import React from 'react';

interface ImageProps {
  src: string; // Renamed from 'path' to 'src'
  alt?: string;
  className?: string; // Optional className prop
  imgClass?: string; // Optional className prop
}

const Image: React.FC<ImageProps> = ({ src, alt, className, imgClass }) => {
  // Assuming the path is correct for your project structure
  const imagePath = `/images/${src}`; 
  const defaultClasses = "w-full h-full object-contain rounded-2xl";
  const defaultImgClass = "rounded-2xl";

  return (
    <div className={className ? className : defaultClasses}>
      <img
        src={imagePath}
        alt={alt ? alt : "sav image"}
        className={imgClass ? imgClass : defaultImgClass}
        loading="lazy"
      />
    </div>
  );
};

export default Image;

import React from 'react';

const MBG = ({ days }) => {
  return (
    <section className="flex rounded-2xl md:flex-row">
      <div className="flex flex-col bg-purple-50 p-8 rounded-2xl max-w-lg mx-auto">
        <h3 className='text-2xl font-bold'>Try it for {days} days risk free</h3>
        <p className=''>Remember you don’t have to say YES right now. You only have to say MAYBE. Go through the materials for <strong>{days} days risk-free from the start of the course</strong>.</p>
      </div>
    </section>
  );
};

export default MBG;

import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from './GlobalState';
import GIF from './components/GIF';
import Popup from './components/Popup';

function CoverLetter() {
  const { user } = useContext(GlobalContext);
  const hasEnoughCredits = user && user.credits >= 3;
  const [jobTitle, setJobTitle] = useState('');
  const [userID, setUserID] = useState('');
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [feedback, setFeedback] = useState('');
  const [popupContent, setPopupContent] = useState(null);
  const [showPopup, setShowPopup] = useState(false);  
  const [isLoading, setIsLoading] = useState(false);

  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupContent(null);
};
useEffect(() => {
  if (user) {
    setUserID(user.id); // Update userID state whenever the user object changes
  }
}, [user]);



  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append('userID', userID);
    formData.append('position', position);
    formData.append('company', company);
    formData.append('job_description', jobDescription);
    if (file) {
      formData.append('resume', file);
    }

    try {
      const response = await fetch('/get-cover-letter', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setPopupContent(
          <div>
            <h1 className='text-4xl'>We got your info!</h1>
            <h2 className='text-2xl pt-4'>You should get an email with us in the next 5 minutes :)</h2>
          </div>
          );
        setShowPopup(true); 
      } else {
        setFeedback('Form submission failed');
      }
    } catch (error) {
      setFeedback(`Error submitting form: ${error}`);
    }
    setIsLoading(false);

  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name); // Update file name for display
      setFeedback(`File ${selectedFile.name} attached successfully.`);
    }
  };

  return (
    <section className="bg-purple-50 py-12 dark:bg-gray-800">
      {isLoading && <GIF />}
      <Popup 
                show={showPopup}
                content={popupContent}
                onClose={handleClosePopup}
            />
      <div className="mx-auto max-w-screen-lg py-8">
        <h1 className="text-5xl font-bold pb-6">Get a Draft Cover Letter Created in 5 Minutes</h1>
        <p>Upload your resume in PDF, paste the job description, and pay 3 credits. Our savvy AI will send you an email with a draft cover letter. Try it below!</p>
      </div>
      <div className="flex flex-col md:flex-row gap-8 max-w-screen-lg mx-4 md:mx-auto">
        <div className="space-y-8">
        <form onSubmit={handleSubmit}>
          <div className="relative z-0 w-full mb-6 group">
            <input
              type="text"
              name="position"
              id="position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
            />
            <label htmlFor="position" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-0 left-0 origin-[0] peer-focus:left-0 peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Position You're Applying To</label>
          </div>

          <div className="relative z-0 w-full mb-6 group">
            <input
              type="text"
              name="company"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
            />
            <label htmlFor="company" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-0 left-0 origin-[0] peer-focus:left-0 peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Company</label>
          </div>

          <div className="relative z-0 w-full mb-6 group">
            <textarea
              name="job_description"
              id="job_description"
              rows="6"
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              required
              placeholder=" "
            ></textarea>
            <label htmlFor="job_description" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform scale-75 -translate-y-6 top-0 left-0 origin-[0] peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0">Paste Job Description</label>
          </div>

          <div className='flex flex-col justify-start items-start text-start'>
            <label htmlFor="resume">Upload Your Resume</label>
            <input
              type="file"
              id="resume"
              name="resume"
              required
              accept="application/pdf"
              onChange={handleFileChange}
            />
          </div>
          {hasEnoughCredits ? (
             <button type="submit" className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Submit</button>
          ) : (
            <div>
              <button type="submit" className="mt-4 bg-gray-500 text-white font-bold py-2 px-4 rounded" disabled>Submit</button>
              <h1 className='py-4 text-2xl'>Based off our data, you have {user.credits} credits which is less than the 3 required.</h1>
            </div>
          )}
         
        </form>
          {feedback && <div className="mt-4 text-sm font-medium">{feedback}</div>}
        </div>
      </div>
    </section>
  );
}

export default CoverLetter;

import React, { useEffect, useState, useRef } from 'react';
import Image from '../components/Image'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion"

const RoboGuide = () => {
  return (
    <body className="bg-green-50">
      <section className="py-6 dark:bg-gray-900 space-y-6">
        <div className="flex flex-col md:flex-row px-4 mx-auto max-w-screen-lg">
          <div className="flex flex-col pb-8 gap-y-6">
            <h1 className="text-center md:text-start font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance capitalize">Robo+: Your Personal Robo-Explorer</h1>
            <Image src='robo-explorer-1x1.jpg' className='block md:hidden'/>
            <h2 className='text-2xl'>$7</h2>
            <p>If you invest using robo advisors or are thinking of using robo advisors, you will want to look at Robo+. It has a list of all robo advisor products in Singapore along with their returns. </p>
            <p>More importantly, you can see how your investments would've done if you invested in the different robo advisors, giving you an idea as to if you're on track for your goals..</p>
            <p>Included:</p>
            <div className='flex flex-col space-2 *:flex *:flex-row *:gap-x-2 *:items-center'>
              <div>
                <Image src='smile-bullet.svg' className='w-4'/>
                <p>A list of all the different robo advisors in Singapore</p>
              </div>
              <div>
                <Image src='smile-bullet.svg' className='w-4'/>
                <p>A list of all 101+ Robo Advisor products & their performance</p>
              </div>
              <div>
                <Image src='smile-bullet.svg' className='w-4'/>
                <p>Suggestions on where the products fit in your investing planning</p>
              </div>
              <div>
                <Image src='smile-bullet.svg' className='w-4'/>
                <p>Allocate fake money and see how it does</p>
              </div>
            </div>
            <a className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 rounded text-center mx-auto px-8" href="https://cart.sav.finance/robo-explorer/">Purchase</a>
          </div>
          <div className='hidden md:block'>
          <Image src='robo-explorer-1x1.jpg'/>
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-xl md:text-4xl dark:text-white text-balance capitalize">Have a Peek Inside👇</h2>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:gap-8 bg-white py-8 mx-4 md:mx-auto max-w-screen-lg xl:gap-16 sm:py-8 px-4 lg:px-6 rounded-3xl border-2 justify-center items-center">
          <div className="order-1 md:order-last">
          <Image src='roboproducts1x1.jpg'/>
          </div>
          <div className="order-2 py-2">
            <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-xl dark:text-white text-balance capitalize pb-4">
            List of all robo advisors and their products
            </h2>
            <p className="text-gray-500 dark:text-gray-400">Building confidence starts with knowing what your options are. We have broke down each product, how they have done over the last 10 years and where you should consider it in your investment portfolio.</p>
            <p className="text-gray-500 dark:text-gray-400">Best part is we will keep updating this information and add new robo advisor products as they become available.</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:gap-8 bg-white py-8 mx-4 md:mx-auto max-w-screen-lg xl:gap-16 sm:py-8  px-4 lg:px-6 rounded-3xl border-2 justify-center items-center">
          <div className="order-1">
          <Image src='robotest1x1.jpg'/>
          </div>
          <div className="order-2 py-2">
            <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-xl dark:text-white text-balance capitalize pb-4">
              Create Your Own Portfolio & See If It Meets Your Financial Needs
            </h2>
            <p className="text-gray-500 dark:text-gray-400">Although past performance does not guarantee future results, it does give an indicator. That said, you can choose up to 3 different robo advisor products and see what happens to it if you were to allocate your investment money to it. We will use historical data and show you what would have happened with your portfolio from 2014 to today.</p>
            <p className="text-gray-500 dark:text-gray-400">You can then tweak it and once you're ready, actually implement your own portfolio with the robo advisors.</p>
          </div>
        </div>
        <div className='flex flex-col mx-auto '>
        <a className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 rounded text-center mx-auto px-8" href="https://cart.sav.finance/robo-explorer/">Purchase</a>
        </div>
      </section>
      <section className='bg-purple-50 mx-auto flex flex-col py-8'>
      <h1 className="text-center md:text-start font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance capitalize mx-auto">FAQ</h1>
      <Accordion type="single" collapsible className='w-3/4 mx-auto'>
        <AccordionItem value="item-1">
          <AccordionTrigger className='text-start text-pretty'>How do I access the resource?</AccordionTrigger>
          <AccordionContent>
            Robo+ is on our sav members site. Once you purchase, you will unlock the page and be able to use it to explore all your robo advisor options.
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-2">
          <AccordionTrigger className='text-start text-pretty'>Why is this not a PDF or Google Sheet?</AccordionTrigger>
          <AccordionContent>
            Our main goal with anything we create is how we can give you the best possible experience. When we thought about a PDF, it would make it very hard to update once Robo Advisors update their own product offerings. Also, making your own portfolio is technically do-able on google sheet but then it's also very hard to maintain and would slow down the sheet a lot. Thus we decided that having it on our own site would make it easier to maintain and update.
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-3">
          <AccordionTrigger className='text-start text-pretty'>Is this up to date?</AccordionTrigger>
          <AccordionContent>
            We not only update the list regularly, we are also planning to make this even more helpful in the future. If there are any robo-advisor related things you want to see as part of this resource, let us know and we'll add it to our roadmap.
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      </section>


    </body>
  

  );
}

export default RoboGuide;
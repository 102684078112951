import React, { useState, useRef } from 'react';

const Info = ({ infoText }) => {
  const [isHovering, setIsHovering] = useState(false);
  const containerRef = useRef(null); // Use a ref to refer to the container element

  return (
    <div ref={containerRef} className="relative inline-block"
         onMouseEnter={() => setIsHovering(true)}
         onMouseLeave={() => setIsHovering(false)}>
      <sup>
    <div className="bg-blue-200 rounded-full text-white size-4 flex items-center justify-center cursor-pointer select-none text-xs">i</div>
  </sup>
  {isHovering && (
    <div className="absolute w-48 bg-white shadow-lg p-4 z-10 -translate-x-1/2 left-1/2 text-sm overflow-hidden">
      <div className="scrollbar-hide max-h-32 overflow-y-auto">
          <p className='text-gray-700 font-normal'>{infoText}</p>
      </div>
    </div>
  )}
    </div>
  );
};

export default Info;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import { Toaster } from "./ui/toaster"
import { useToast } from "./ui/use-toast"
import {Button} from "./ui/button"
import {Input} from "./ui/input"
import GIF from './GIF';

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form"

const formSchema = z.object({
  name: z.string().min(2, { message: "Name must be at least 2 characters." }),
  email: z.string().email({ message: "Please enter a valid email address." }),
});

interface ProfileFormProps {
  CTA: string;
  fetch_url: string;
  success?: string;
  fail?: string;
}

const BasicForm = ({ CTA, fetch_url, success = "Submission successful!", fail = "There was an error with your submission." }: ProfileFormProps) => {
  const { toast } = useToast()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    try {
      const response = await fetch(fetch_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        toast({title: fail,})
        setIsLoading(false)
        return;
      }

      toast({title: success})
    } catch (error) {
      toast({title: fail})
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
{isLoading ? (
  <GIF src='loading.mp4'/>
) : (
  <div>
        <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className='w-full grid gird-cols-1 md:grid-cols-2 md:space-x-8'>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="Xiao Ming" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Your Best Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        </div>
        <Button type="submit" className='bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded w-full'>{CTA}</Button>
      </form>
    </Form>
      <Toaster/>
      </div>
      )}
    </div>
  );
};

export default BasicForm
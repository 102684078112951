import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation, Navigate } from 'react-router-dom';
import { GlobalProvider } from './pages/GlobalState';
// import { useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ProfilePage from './pages/ProfilePage';
import Workshop from './pages/Workshop';
import Nav from './pages/components/Nav';
import GeneralNav from './pages/components/GeneralNav';
import Resources from './pages/Resources';
import CareerPage from './pages/CareerPage';
import Login from './pages/Login';
import Robo from './pages/Robo';
import CrystalBall from './pages/CrystalBall';
import RoboGuide from './pages/lp/RoboGuide';
import InvestingCourse from './pages/lp/InvestingCourse';
import RetirementLP from './pages/lp/RetirementLP';
import MoneyPlanner from './pages/lp/MoneyPlanner';
import GoalCalculator from './pages/GoalCalculator';
import MoneyDiary from './pages/money-diary';
import {WeddingDiary} from './pages/lp/moneyDiary';
import {MoneyDiarStart} from './pages/lp/moneyDiary';
import Main from './pages/lp/Main';
import Org from './pages/lp/Org';
import CollectionProducts from './pages/lp/CollectionProducts';
import CollectionFree from './pages/lp/CollectionFree';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import TagManager from 'react-gtm-module'
import { Toaster } from "./pages/components/ui/toaster"

const tagManagerArgs = {
  gtmId: 'GTM-NQK4JWZ'
}
TagManager.initialize(tagManagerArgs)

const useGTMPageView = () => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: location.pathname,
      },
    });
  }, [location.pathname]);
};

const MemberLayout = () => (
  <>
    <Nav />
    <Outlet />
  </>
);

const GeneralLayout = () => {
  useGTMPageView();

  return (
    <>
      <GeneralNav />
      <Outlet />
    </>
  )
};

const App = () => {
    return (
      <Router>
        <Routes>
          <Route path='/' element={<GeneralLayout/>}>
            <Route index element={<Main />} />
            <Route path="work-with-us" element={<Org />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
          </Route>
          <Route path="/my/" element={<GlobalProvider> <MemberLayout/> </GlobalProvider>}>
              <Route index element={<HomePage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="profile" element={<ProfilePage />} />
              <Route path="workshop" element={<Workshop />} />
              <Route path="workshop/:id" element={<Workshop />} />
              <Route path="resources" element={<Resources />} />
              <Route path="career" element={<CareerPage />} />
              <Route path="robo-guide" element={<Robo />} />
              <Route path="crystal-ball" element={<CrystalBall />} />
          </Route>
          <Route path="/lp/*" element={<GeneralLayout/>}>
            <Route index element={<MoneyDiarStart />} />
            <Route path="money-diary" element={<MoneyDiarStart />} />
          </Route>
          <Route path="/products/*" element={<GeneralLayout/>}>
            <Route index element={<CollectionProducts />} />
            <Route path="collection" element={<CollectionProducts />} />
            <Route path="robo-explorer" element={<RoboGuide />} />
            <Route path="investing-course" element={<InvestingCourse />} />
            <Route path="money-planner" element={<MoneyPlanner />} />
          </Route>
          <Route path="/free/*" element={<GeneralLayout/>}>
            <Route index element={<CollectionFree />} />
            <Route path="goal-calculator" element={<GoalCalculator />} />
            <Route path="retirement-calculator" element={<RetirementLP />} />
          </Route>
          <Route path="/challenge/" element={<GeneralLayout/>}>
            <Route path="money-diary" element={<MoneyDiary />} />
            <Route path="wedding-diary" element={<WeddingDiary />} />
          </Route>
          <Route path="/member/*" element={<GeneralLayout/>}>
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
          </Route>
        </Routes>
        <Toaster />
        </Router>
    );
};

export default App;


import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth } from './components/firebaseConfig'; // Adjust the path as necessary
import Popup from './components/Popup';
import GIF from './components/GIF';
import Image from './components/Image';
import { Link } from 'react-router-dom';
// import { buttonVariants } from "tsxcomponents/ui/button"

const EmailInput = ({ email, setEmail }) => {
  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase()); // Update state with the value from the input
  };

  return (
      <div className="relative z-0 w-full mb-6 group">
          <input 
              type="text" 
              name="email" 
              id="email" 
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
              placeholder=" " 
              value={email} // Ensure this is a string
              onChange={handleEmailChange} 
              required 
          />
          <label htmlFor="email" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-0 left-0 origin-[0] peer-focus:left-0 peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Email</label>
      </div>
  );
};

const PasswordInput = ({ password, setPassword }) => {
  const handlePasswordChange = (e) => {
      setPassword(e.target.value); // Update state with the value from the input
  };

  return (
      <div className="relative z-0 w-full mb-6 group">
          <input 
              type="password" 
              name="password" 
              id="password" 
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
              placeholder=" " 
              value={password} // Ensure this is a string
              onChange={handlePasswordChange} 
              required 
          />
          <label htmlFor="password" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-0 left-0 origin-[0] peer-focus:left-0 peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Password</label>
      </div>
  );
};

const DisplayLoading = () => {
  return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50 h-full w-full">
        <div class="relative top-20 max-w-screen-lg mx-auto p-5 shadow-lg rounded-md">
          {<GIF />}
          </div>
      </div>
  );
};


function Register() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [popupContent, setPopupContent] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const handleClosePopup = () => {
        setShowPopup(false);
        setPopupContent(null);
    };


    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
              setIsLoggedIn(true);
          } else {
              setIsLoggedIn(false);
          }
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
};

  const err = (
    <div className='flex flex-col'>
      <h1 className='text-4xl'>It seems like you already have an account</h1>
      <p className='text-gray-500'>If that's the case, please <a href="/member/login" className='text-green-600 font-bold underline'>log in here</a>.</p>
      <p className='text-gray-500'>If you forgot your password, you can <a href="/forgot-password" className='text-green-600 font-bold underline'>reset it here</a>.</p>
    </div>
  )


  const handleRegister = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch('/my/api/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: name, email: email, password: password }),
    })
    .then(response => {
        if (!response.ok) {
          setPopupContent(err);
          setShowPopup(true);
            return response.json().then(err => { throw err; });
        }
        return response.json();
    })
    .then(data => {
        window.location.href = '/my/profile'; // Redirect on successful registration
    })
    .catch(error => {
        if (error && error.error) {
            setPopupContent(err);
            setShowPopup(true);
        } else {
            setErrorMessage("An unexpected error occurred");
        }
        // Display popup here
        setLoading(false);
    });
};

    return (
      <div className="flex flex-col md:flex-row items-center h-fit">
        <Popup 
                show={showPopup}
                content={popupContent}
                onClose={handleClosePopup}
            />
          <div className="flex order-3 md:order-1">
            <Image src='login.png' className='max-h-screen' imgClass='rounded-none'/>
          </div>
          <div className="p-4 md:p-8 flex-col order-2">
          {loading && <DisplayLoading />}
            {/* <div className='max-w-2xl space-6 py-8 *:py-2'>
              <h1 className='text-4xl'>It's sav's birthday 🎉</h1>  
              <h2 className='text-2xl'>Get free credits to claim free resources 🎁</h2>
              <p>We've created a bunch of resources and some of them are paid. But now you can get 40 free credits and redeem them for these resources! All you have to do is log in and claim them.</p>
              <p>Don't have an account? create one below 👇</p>
            </div> */}
              <h1 className="font-serif text-4xl font-bold leading-none lg:text-5xl mb-8">Register</h1>
              <p className="mb-8">Register for your free account today to get access to a bunch of different resources to help you navigate money and adulting.</p>
              <form onSubmit={handleRegister} className="space-8 max-w-lg">
              <div className="relative z-0 w-full mb-6 group">
          <input 
              type="text" 
              name="name" 
              id="name" 
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
              placeholder=" " 
              value={name}
              onChange={handleNameChange}
              required 
          />
          <label htmlFor="email" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-0 left-0 origin-[0] peer-focus:left-0 peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Name</label>
      </div>
                <EmailInput email={email} setEmail={setEmail} />
                <PasswordInput password={password} setPassword={setPassword} />
              <button type="submit" className="w-full bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">Register</button>
                      
                  <p className="pt-4">Already have an account? <a href="/login" className="text-green-700 font-bold">Login here</a></p>
                  <p className="pt-4">Forgot your password? <a href="/forgot-password" className="text-green-700 font-bold">Reset password here</a></p>
              </form>
          </div>
      </div>
  );
  
}

export default Register;
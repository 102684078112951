import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Link } from 'react-router-dom';
import Image from './components/Image';
import { Toaster } from "./components/ui/toaster";
import { useToast } from "./components/ui/use-toast";
import GIF from './components/GIF';

function ForgotPassword() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const auth = getAuth();
    const { toast } = useToast();

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submit action
        setLoading(true); // Optional: Show loading indicator

        try {
            await sendPasswordResetEmail(auth, email);
            console.log("Password reset email sent");
            toast({
                title: `✅ Success!`,
                description: `Check your inbox for further instructions`,
            });
        } catch (error) {
            console.error("Failed to send password reset email", error);
            toast({
                title: `❌ Error!`,
                description: `Failed to send password reset email. Please check if the email you submitted is correct.`,
            });
        } finally {
            setLoading(false); // Optional: Hide loading indicator
        }
    };

    return (
      <div className="login-container flex flex-col md:flex-row items-center h-screen">
          <div className="flex order-3 md:order-1 md:w-1/2">
            <Image src="login.png" alt="sav-login" className="max-h-screen" imgClass='rounded-none'/>
          </div>
          <div className="p-4 md:p-8 flex-col order-2">
              {loading && <GIF />}
              <p className="pb-4">Go <a href="/" className="text-green-700 font-bold">back to sav homepage</a></p>
              <h1 className="font-serif text-4xl font-bold leading-none lg:text-5xl mb-8">Forgot Password?</h1>
              <p className="mb-4">Reset your password below.</p>
              <form onSubmit={handleSubmit}>
                  <div className="relative z-0 w-full mb-6 group">
                      <input 
                          type="email" 
                          name="email" 
                          id="email" 
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                          placeholder=" " 
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required 
                      />
                      <label htmlFor="email" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-0 left-0 origin-[0] peer-focus:left-0 peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
                  </div>
                  <button type="submit" className="w-full bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">Reset Password</button>
              </form>
          </div>
          <Toaster />
      </div>
  );
}

export default ForgotPassword;

"use client"
import React, { useEffect, useState, useMemo } from 'react';
import Helmet from 'react-helmet'
import Image  from "../components/Image"
import ImageGrid from '../components/ImageGrid'
import { useForm } from "react-hook-form"
import { useToast } from "../components/ui/use-toast"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion"



import { Button } from "../components/ui/button"

const MoneyDiarStart = () => {
  const { toast } = useToast()
  const { register, handleSubmit } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  

  async function onSubmit(values) {
    const lowercaseEmail = values.email ? values.email.toLowerCase() : '';
  
    try {
      const response = await fetch('../my/api/money-diary-start', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...values, email: lowercaseEmail }),
      });
  
      if (!response.ok) {
        toast({
          title: 'Whoops...',
          description: 'It seems like we already have a request from you. If this is a mistake, please email us at admin@sav.finance'
        })
      } else {
        const data = await response.json();
        console.log(data)
        if(data.status === 'page2') {
          setIsSubmitted(true);
          toast({
            title: 'Success',
            description: "You're on your way to submitting a money diary!"
          })
        } else if (data.status === 'complete') {
          toast({
            title: 'Success',
            description: "Let's get started with day 1!"
          })
          window.location.href=('/challenge/money-diary');
        }
      }
    } catch (error) {
      toast({
        title: 'Whoops...',
        description: 'It seems like we already have a request from you. If this is a mistake, please email us at admin@sav.finance'
      })
    }
  }

  if (isSubmitted) {
    return (
      <div className='flex flex-col max-w-screen-2xl mx-auto px-4 md:px-0'>
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="px-4 mx-auto max-w-screen-sm text-center lg:px-6 mb-8 lg:mb-16">
              <h2 className="primary-h2 ">A few more questions...</h2>
              <p>This will help the readers better understand your situation and inspire them to think about it themselves.</p>
          </div>
          <div className="grid lg:gap-8 ">
              <div className="mb-8 lg:mb-0">
                  <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-8 mx-auto max-w-screen-md sm:grid-cols-2">
                  <input type="number" id="page"  {...register('page')} value='2' className="hidden"/>
                      <div className="sm:col-span-2">
                          <label for="relation_with_money" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">How would you describe your relationship with money?</label>
                          <textarea id="relation_with_money" rows="3"{...register('relation_with_money')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Are you a saver? A spender? Are you happy with your relationship? Why or why not?"></textarea>
                    </div>
                    <div className="sm:col-span-2">
                          <label for="financial_support" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Did you recieve / are you recieving any financial support from others?</label>
                          <textarea id="financial_support" rows="3"{...register('financial_support')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Tuition, spending $ etc"></textarea>
                    </div>
                    <div className="sm:col-span-2">
                          <label for="money_goals" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">What are your current money goals?</label>
                          <textarea id="money_goals" rows="3"{...register('money_goals')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Are you trying to save a certain amount, pay back debt or make a big purchase?"></textarea>
                    </div>
                    <div className="sm:col-span-2">
                          <label for="spending_weakness" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">What's your spending weakness??</label>
                          <textarea id="spending_weakness" rows="3"{...register('spending_weakness')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Mine is Studio Ghibli items..."></textarea>
                    </div>
                    <div className="sm:col-span-2">
                          <label for="spending_relationship" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">How has your relationship with spending evolved over the years?</label>
                          <textarea id="others" rows="3"{...register('spending_relationship')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a comment..."></textarea>
                    </div>
                    <div className="sm:col-span-2">
                          <label for="others" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Anything else you'd like us to know / share on your submission with our audience?</label>
                          <textarea id="others" rows="3"{...register('others')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Any suggestions or tips?"></textarea>
                    </div>
                      <button type="submit" className="text-white bg-green-400 px-4 py-2 rounded-xl hover:bg-green-400/90 w-full">Submit</button>
                  </form>
              </div>
          </div>
      </div>
      </div>
    );
  }
  

  return (
    <div>
      <Helmet>
        <title>sav Money Diary</title>
        <meta name="description" content="Share your Money Diary with the sav community"/>
        <meta property="og:title" content="sav Money Diary"/>
        <meta property="og:description" content="Share your Money Diary with the sav community!"/>
        <meta property="og:image" content="/images/sav.png"/>
        <meta property="og:url" content="https://www.sav.finance/lp/money-diary"/>
      </Helmet>
      <div className='flex flex-col max-w-screen-2xl mx-auto px-4 md:px-0'>
        <div className='flex flex-col max-w-4xl mx-auto py-8 gap-8'>
        <h1 className='primary-h1  text-center'>Want to inspire others about their money spending habits?</h1>
        <p className='text-center'>Do a 7 day Money Diary challenge & share your expenses with us!</p>
        </div>
        <a href="#get-started" className="primary-button1 text-white text-center mx-auto">Get started →</a>
        <div className='flex flex-col max-2-screen-2xl mx-auto px-4 md:px-0'>
        <div id='forMembers' className='flex flex-col max-w-screen-lg mx-auto py-3 gap-12'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-16'>
              <div className='flex flex-col md:basis-1/3 items-start'>
                <div className='flex flex-row md:flex-col gap-4'>
                <Image src="anonymous.svg" alt="5" imgClass='aspect-square' className='w-8 h-8 flex-none pt-2'/>
                  <div className='flex flex-col gap-2'>
                    <p className='primary-h3'>Totally Anonmyous</p>
                    <p className='text-gray-500'>We actually want you to use an alias!</p>
                  </div>
                  </div>
              </div>
              <div className='flex flex-col md:basis-1/3 items-start'>
                <div className='flex flex-row md:flex-col gap-4'>
                <Image src="perfect.svg" alt="5" imgClass='aspect-square fill-green-200' className='w-8 h-8 flex-none pt-2'/>
                  <div className='flex flex-col gap-2'>
                    <p className='primary-h3'>Easy to complete</p>
                    <p className='text-gray-500'>Takes less than 5minutes a day.</p>
                  </div>
                  </div>
              </div>
              <div className='flex flex-col md:basis-1/3 items-start'>
                <div className='flex flex-row md:flex-col gap-4'>
                <Image src="time.svg" alt="5" imgClass='items-center aspect-square fill-green-200' className='w-8 h-8 flex-none pt-2 '/>
                  <div className='flex flex-col gap-2'>
                    <p className='primary-h3'>Flexible</p>
                    <p className='text-gray-500'>Submit one day at a time or submit it all at once.</p>
                  </div>
                  </div>
              </div>
              <div className='flex flex-col md:basis-1/3 items-start'>
                
              </div>
            </div>
            
          </div>
        </div>
      </div>
      
      <div id='how it works' className='flex flex-col'>
        <div className='flex flex-col md:flex-row gap-14 max-w-screen-lg mx-auto px-4 py-8'>
          <div className='object-scale-down h-1/2 flex basis-1/2'>
            <ImageGrid urls={['md_1.jpg','md_2.jpg','md_3.jpg','md_4.jpg','md_5.jpg']}
            maxHeight="h-1/2"/>
          </div>
          <div className='order-2 flex flex-col justify-center basis-1/2 gap-8'>
            <h2 className=' primary-h2 mb-0'>How it works</h2>
            <div className='gap-2 py-2'>
              <div className='flex flex-row gap-6 py-2'>
              <Image src="smile-bullet.svg" className='w-8 h-8 flex-none pt-2'/>
                <div className='flex flex-col'>
                  <h3 className='primary-h3 font-semibold'>Step 1: Fill out the form above</h3>
                  <p className='text-gray-500'>Tell us a bit about yourself and your relationship with money. After submitting, we will send an email where you can either connect to our telegram bot to get daily reminders or you can opt to get the reminders via email.</p>
                </div>
              </div>
              <div className='flex flex-row gap-6 py-2'>
              <Image src="smile-bullet.svg" className='w-8 h-8 flex-none pt-2'/>
                <div className='flex flex-col'>
                  <h3 className='primary-h3 font-semibold'>Step 2: Track your daily spend</h3>
                  <p className='text-gray-500'>Track your spend each day and reflect on whether you're satisfied with the way you used your money. This will also give others ideas as to how they can manage their own finances.</p>
                </div>
              </div>
              <div className='flex flex-row gap-6 py-2'>
                <Image src="smile-bullet.svg" className='w-8 h-8 flex-none pt-2'/>
                <div className='flex flex-col'>
                  <h3 className='primary-h3 font-semibold'>Step 3: Submit your spending each day</h3>
                  <p className='text-gray-500'>We will send you a telegram message each day asking you to fill out your expenses so that you don't have to remember it a week later. Don't use telegram, that's okay as we can also send you an email!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="px-4 mx-auto max-w-screen-sm text-center lg:px-6 mb-8 lg:mb-16">
              <h2 id='get-started' className="primary-h2 ">Ready to get started?</h2>
          </div>
          <div className="grid lg:gap-8 ">
              <div className="mb-8 lg:mb-0">
                  <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-8 mx-auto max-w-screen-md sm:grid-cols-2">
                      <div>
                          <label for="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Alias</label>
                          <input type="text" id="first-name" {...register('firstName')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Xiao Ming" required/>
                      </div>
                      <div>
                          <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                          <input type="email" id="email" {...register('email')} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@gmail.com" required/>
                      </div>
                      <div>
                          <label for="occupation" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Occupation</label>
                          <input type="text" id="occupation" {...register('occupation')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required/>
                      </div>
                      <div>
                          <label for="salary" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Monthly Salary</label>
                          <input type="number" id="salary" {...register('salary')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required/>
                      </div>
                      <div>
                          <label for="savings" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Savings</label>
                          <input type="number" id="savings" {...register('savings')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required/>
                      </div>
                      <div>
                          <label for="investments" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Investments</label>
                          <input type="number" id="investments"  {...register('investments')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required/>
                      </div>
                      <div>
                          <label for="cpg" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">CPF</label>
                          <input type="number" id="cpf" {...register('cpf')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required/>
                      </div>
                      <div>
                          <label for="debt" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Approx Debt</label>
                          <input type="number" id="debt"  {...register('debt')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required/>
                      </div>
                      <div>
                          <label for="full" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">What is something you would buy at full price?</label>
                          <input type="text" id="full" {...register('full')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required/>
                      </div>
                      <div className="sm:col-span-2">
                      <button type="submit" className="primary-button1 w-full">Start Money Diary</button>    
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </section>

    </div>
  );
}

const WeddingDiary = () => {
  const { toast } = useToast()
  const { register, handleSubmit } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  

  async function onSubmit(values) {
    const lowercaseEmail = values.email ? values.email.toLowerCase() : '';
  
    try {
      const response = await fetch('../my/api/money-diary-start', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...values, email: lowercaseEmail }),
      });
  
      if (!response.ok) {
        toast({
          title: 'Whoops...',
          description: 'It seems like we already have a request from you. If this is a mistake, please email us at admin@sav.finance'
        })
      } else {
        const data = await response.json();
        console.log(data)
        if(data.status === 'page2') {
          setIsSubmitted(true);
          toast({
            title: 'Success',
            description: "You're on your way to submitting a money diary!"
          })
        } else if (data.status === 'complete') {
          toast({
            title: 'Success',
            description: "Let's get started with day 1!"
          })
          window.location.href=('/challenge/money-diary');
        }
      }
    } catch (error) {
      toast({
        title: 'Whoops...',
        description: 'It seems like we already have a request from you. If this is a mistake, please email us at admin@sav.finance'
      })
    }
  }

  

  return (
    <div>
      <Helmet>
        <title>sav Wedding Diary</title>
        <meta name="description" content="Share your Money Diary with the sav community"/>
        <meta property="og:title" content="sav Money Diary"/>
        <meta property="og:description" content="Share your Money Diary with the sav community!"/>
        <meta property="og:image" content="/images/sav.png"/>
        <meta property="og:url" content="https://www.sav.finance/lp/money-diary"/>
      </Helmet>
      <div className='flex flex-col max-w-screen-2xl mx-auto px-4 md:px-0'>
        <div className='flex flex-col max-w-4xl mx-auto py-8 gap-8'>
        <h1 className='primary-h1  text-center'>Want to inspire others about their money spending habits?</h1>
        <p className='text-center'>Do a 7 day Money Diary challenge & share your expenses with us!</p>
        </div>
      </div>

      <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="grid lg:gap-8 ">
              <div className="mb-8 lg:mb-0">
                  <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-8 mx-auto max-w-screen-md sm:grid-cols-2">
                      <div>
                          <label for="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Alias for you and your partner</label>
                          <input type="text" id="first-name" {...register('firstName')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Xiao Ming" required/>
                      </div>
                      <div>
                          <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                          <input type="email" id="email" {...register('email')} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@gmail.com" required/>
                      </div>
                      <div>
                          <label for="occupation" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">The big day</label>
                          <input type="text" id="occupation" {...register('occupation')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="DD-MM-YYYY" required/>
                      </div>
                      <div>
                          <label for="location" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Location</label>
                          <input type="text" id="location" {...register('location')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required/>
                      </div>
                      <div className="sm:col-span-2">
                          <label for="costs" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Let's break down the costs</label>
                          <textarea id="costs" rows="3"{...register('cpsts')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Some ideas: venue, photographer, catering, dress + tuxe, makeup, wedding band?"></textarea>
                    </div>
                    <div>
                          <label for="paid_savings" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Paid via savings?</label>
                          <input type="number" id="paid_savings" {...register('paid_savings')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required/>
                      </div>
                      <div>
                          <label for="paid_family" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Paid via family?</label>
                          <input type="number" id="paid_family" {...register('paid_family')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required/>
                      </div>
                      <div>
                          <label for="paid_debt" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Paid via debt?</label>
                          <input type="number" id="paid_debt" {...register('paid_debt')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required/>
                      </div>
                      <div className="sm:col-span-2">
                          <label for="venue" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">How did you decide on the venue?</label>
                          <textarea id="venue" rows="3"{...register('venue')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Some ideas: venue, photographer, catering, dress + tuxe, makeup, wedding band?"></textarea>
                    </div>
                    <div className="sm:col-span-2">
                          <label for="splurge" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">What parts did you splurge on and where did you cut costs?</label>
                          <textarea id="splurge" rows="3"{...register('splurge')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Some ideas: venue, photographer, catering, dress + tuxe, makeup, wedding band?"></textarea>
                    </div>
                    <div className="sm:col-span-2">
                          <label for="expectations" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Were there any initial mismatch of expectations (partner / parents)? If so how did you navigate it?</label>
                          <textarea id="expectations" rows="3"{...register('expectations')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Some ideas: venue, photographer, catering, dress + tuxe, makeup, wedding band?"></textarea>
                    </div>
                    <div className="sm:col-span-2">
                          <label for="advice" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">What advice do you have for others who are going to start wedding planning?</label>
                          <textarea id="advice" rows="3"{...register('advice')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Some ideas: venue, photographer, catering, dress + tuxe, makeup, wedding band?"></textarea>
                    </div>
                      
                      <div className="sm:col-span-2">
                      <button type="submit" className="primary-button1 w-full">Submit wedding diary</button>    
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </section>
      <div id='FAQ' className='bg-white'>
        <div className='flex flex-col max-w-screen-2xl mx-auto px-4 py-8 gap-4'>
        <h2 className='primary-h2 text-center'>Frequently asked questions</h2>
        <div className="w-full mx-auto max-w-2xl *:*:*:*:*:py-1 ">
        <Accordion type="single" collapsible>
          <AccordionItem value="free">
            <AccordionTrigger className='text-start w-full'>Is it really free?</AccordionTrigger>
            <AccordionContent>
            <p>Yes it is!</p>

            <p>Our priority is to provide you with valuable and personalised advice without stress on your end. This unique arrangement allows us to bring together serious experts who genuinely want to help you make informed decisions about your insurance options.</p>

            <p>Your access to expert opinions and guidance comes at no expense to you. We believe that everyone deserves access to reliable insurance advice, and our free service reflects that commitment.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="workwith">
            <AccordionTrigger className='text-start'>Do I have to work with one of the insurance experts?</AccordionTrigger>
            <AccordionContent>
            <p>Not at all! We understand that finding the right fit is essential, which is why we provide you with up to 5 personalised insurance options from a diverse range of experts. Our goal is to ensure you have the freedom to choose what works best for you.</p>

            <p>The experts we connect you with come from various reputable companies, including at least one independent insurance expert - ensuring you receive a well-rounded selection.</p>

            <p>We hope that the suggestions provided by our experts meet your needs and preferences. However, if none of them are tailored to your liking, there's no requirement or obligation to work with any of the experts recommended.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="foreigner">
            <AccordionTrigger className='text-start'>I'm a foreigner, can I still get the insurance proposals?</AccordionTrigger>
            <AccordionContent>
            <p>If you've a valid work pass in Singapore, you are eligible to apply and receive personalised insurance proposals.

But if you don't currently hold a work pass, there are still options available for you. Message us on Telegram for more details. ☺️</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="whoareyou">
            <AccordionTrigger className='text-start'>Who is Whatins?</AccordionTrigger>
            <AccordionContent>
            <p>We run one of the most well known personal finance / adulting brands in Singapore called sav.Finance. Lately, we've been receiving an influx of direct messages from our followers and readers, all seeking guidance on insurance matters. That's why we created this website - to provide you with the necessary support and assistance in navigating the complex world of insurance options.</p>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        </div>
        </div>
      </div>
    </div>
  );
}

export {MoneyDiarStart};
export { WeddingDiary }
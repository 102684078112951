import React, { useEffect, useState, useRef } from 'react';
import Image from '../components/Image'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion"

const CollectionFree = () => {
  return (
    <body className="bg-msc-bg">
      <div className='flex flex-col mx-4 md:mx-auto max-w-4xl py-8'>
        <h1 className='text-4xl md:text-6xl text-start md:text-center font-serif py-8'>Free Resources</h1>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-6 *:flex *:flex-col *:gap-2 *:border *:rounded-2xl *:p-4'>
          <a href="/free/retirement-calculator" className='hover:bg-green-50'>
            <Image src='retirement-calculator-1x1.png' className='flex-none'/>
            <h2 className='text-2xl text-start'>Retirement Calculator</h2>
            <p className='grow'> </p>
            <p className='text-start'>See the minimum you need to invest in order to have a certain income each month during retirement.</p>
          </a>
          <a href="/free/goal-calculator" className='hover:bg-green-50'>
            <Image src='goal-calculator-1x1.png' className='flex-none'/>
            <h2 className='text-2xl text-start'>Goal Calculator</h2>
            <p className='grow'> </p>
            <p className='text-start'>This calculator will help determine the minimum you need to invest to achieve the goals you have.</p>
          </a>
        </div>
      </div>


    </body>
  

  );
}

export default CollectionFree;
import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth } from './components/firebaseConfig'; // Adjust the path as necessary
import Popup from './components/Popup';
import GIF from './components/GIF';
import { Link } from 'react-router-dom';
import Image from './components/Image'
// import { buttonVariants } from "tsxcomponents/ui/button"

const EmailInput = ({ email, setEmail }) => {
  const handleEmailChange = (e) => {
      setEmail(e.target.value); // Update state with the value from the input
  };

  return (
      <div className="relative z-0 w-full mb-6 group">
          <input 
              type="text" 
              name="email" 
              id="email" 
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
              placeholder=" " 
              value={email} // Ensure this is a string
              onChange={handleEmailChange} 
              required 
          />
          <label htmlFor="email" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-0 left-0 origin-[0] peer-focus:left-0 peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Email</label>
      </div>
  );
};

const PasswordInput = ({ password, setPassword }) => {
  const handlePasswordChange = (e) => {
      setPassword(e.target.value); // Update state with the value from the input
  };

  return (
      <div className="relative z-0 w-full mb-6 group">
          <input 
              type="password" 
              name="password" 
              id="password" 
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
              placeholder=" " 
              value={password} // Ensure this is a string
              onChange={handlePasswordChange} 
              required 
          />
          <label htmlFor="password" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-0 left-0 origin-[0] peer-focus:left-0 peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> Password</label>
      </div>
  );
};


function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [popupContent, setPopupContent] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const handleClosePopup = () => {
        setShowPopup(false);
        setPopupContent(null);
    };


    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
              setIsLoggedIn(true);
          } else {
              setIsLoggedIn(false);
          }
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
  }, []);

  const err = (
    <div className='flex flex-col'>
      <h1 className='text-4xl'>It seems like you already have an account</h1>
      <p className='text-gray-500'>If that's the case, please <a href="/member/login" className='text-green-600 font-bold underline'>log in here</a>.</p>
      <p className='text-gray-500'>If you forgot your password, you can <a href="/forgot-password" className='text-green-600 font-bold underline'>reset it here</a>.</p>
    </div>
  )


    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Send the ID token to your backend via HTTPS
                userCredential.user.getIdToken().then((idToken) => {
                    // Post the ID token to your backend
                    fetch('/my/api/login', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email: email, idToken: idToken }),
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.error) {
                            setPopupContent(data.error);
                            setShowPopup(true);
                            setErrorMessage(data.error);
                        } else {
                            window.location.href = '/my/resources'; // or handle successful login differently
                        }
                    });
                    setLoading(false);
                });
            })
            .catch((error) => {
                setPopupContent(error.message);
                setShowPopup(true);
                setErrorMessage(error.message);
                setLoading(false);
            });
    };

    return (
      <div className="login-container flex flex-col md:flex-row items-center h-screen">
        <Popup 
                show={showPopup}
                content={popupContent}
                onClose={handleClosePopup}
            />
          <div className="flex order-3 md:order-1">
            <Image src="login.png" alt="sav-login" className="max-h-screen" imgClass='rounded-none'/>
          </div>
          <div className="p-4 md:p-8 flex-col order-2">
          {loading && <GIF />}
              <p className="pb-4">Go <a href="/" className="text-green-700 font-bold">back to sav homepage</a></p>
              <h1 className="font-serif text-4xl font-bold leading-none lg:text-5xl mb-8">Login</h1>
              <p className="mb-4">Log in to get access to a bunch of different resources to help you navigate money and adulting.</p>
              <form onSubmit={handleLogin} className="space-8 max-w-lg">
              <EmailInput email={email} setEmail={setEmail} />
              <PasswordInput password={password} setPassword={setPassword} />
              <button type="submit" className="w-full bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">Log In</button>
                      
                  <p className="pt-4">Forgot password? <a href="/forgot-password" className="text-green-700 font-bold">Reset here</a></p>
                  <p className="pt-4">No Account? <a href="/member/register" className="text-green-700 font-bold">Register here</a> (free)</p>
                  {errorMessage && <p>{errorMessage}</p>}
              </form>
          </div>
      </div>
  );
  
}

export default Login;
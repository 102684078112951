import React, { useState, useEffect } from 'react';
import { withGlobalState } from './components/HOC';
import GIF from './components/GIF';
import Popup from './components/Popup';

const ProductsPage = ({ user, loading, error }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productTypes, setProductTypes] = useState(new Set());
  const [selectedTypes, setSelectedTypes] = useState(new Set());
  const [searchText, setSearchText] = useState('');
  const [owned, setOwned] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [purchasedProductName, setPurchasedProductName] = useState('');
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

    const handleClosePopup = () => {
        setShowPopup(false);
        setPopupContent(null);
    };



  useEffect(() => {
    setIsLoading(true); // Start loading before the fetch begins
  
    fetch('/my/api/resources')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data.resources) && Array.isArray(data.types)) {
          setProducts(data.resources);
          setFilteredProducts(data.resources); // Initialize with all products
          setProductTypes(new Set(data.types)); // Set the product types
        } else {
          console.error('Received data is not in the correct format:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      })
      .finally(() => {
        setIsLoading(false); // End loading after fetch is complete or failed
      });
  }, []);

  useEffect(() => {
  const trimmedSearchText = searchText.trim().toLowerCase();

  const filtered = products.filter(product => {
    const filenameMatch = product.filename.toLowerCase().includes(trimmedSearchText);
    const summaryMatch = product.summary.toLowerCase().includes(trimmedSearchText);
    const ownershipMatch = !owned || product.owned === owned;
    const typeMatch = selectedTypes.size === 0 || selectedTypes.has(product.type);

    return (filenameMatch || summaryMatch) && ownershipMatch && typeMatch;
  });

  setFilteredProducts(filtered);
}, [searchText, owned, selectedTypes, products]);

  if (isLoading) {
    return <GIF />;
  }
  
  const handleTypeChange = (type, checked) => {
    const newSelectedTypes = new Set(selectedTypes);
    if (checked) newSelectedTypes.add(type);
    else newSelectedTypes.delete(type);
    setSelectedTypes(newSelectedTypes);
  };

  const clearFilters = () => {
    console.log("Clearing filters");  // Debugging
    setSearchText('');
    setOwned(false);
    setSelectedTypes(new Set());
  };

  const handlePurchase = async (product) => {
    setIsPurchasing(true);
    try {
      const response = await fetch('/my/api/dashboard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ resource_id: product.recordID }),
      });
  
      if (response.ok) {
        setPurchaseSuccess(true);
        setPurchasedProductName(product.filename);
        setPopupContent(
          <div>
            <h1 className='text-4xl'>Successfully purchased {product.filename}</h1>
            <h2 className='text-2xl pt-4'>Please refresh the page to see it reflected in your account</h2>
          </div>
          );
        setShowPopup(true);
      } else {
        setPopupContent(
          <div>
            <h1 className='text-4xl'>Purchased failed; not enough credits</h1>
          </div>
          );
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error submitting purchase:', error);
    }
    setIsPurchasing(false);
  };
  

  return (
    <div>
    {isPurchasing ? (
      <GIF src="loading.mp4" />
      ) : (
    <section className="py-6 dark:bg-gray-900 bg-purple-100 min-h-dvh">
      
      <Popup 
      show={showPopup}
      content={popupContent}
      onClose={handleClosePopup}
      />
      
    <div className="grid mx-auto max-w-screen-xl py-8 justify-items-center">
      <h1 className="text-center font-serif font-extrabold tracking-tight leading-none text-gray-900 text-5xl dark:text-white text-balance capitalize">Welcome { !loading && user && user.name && user.name }</h1>
      <p className="text-center text-2xl">This is the list of all the resources.</p>
      {user && (
        <p className="text-center">You have {user.credits} Credits.</p>
      ) }
    </div>
    <div className="grid grid-cols-1 md:grid-cols-5 mx-auto max-w-screen-xl justify-items-center">
      <div id="filter" class="col-span-1 bg-white p-6 rounded-lg shadow-lg max-w-sm md:mr-6">
        <FilterPanel 
          searchText={searchText}
          setSearchText={setSearchText}
          owned={owned}
          setOwned={setOwned}
          productTypes={productTypes}
          selectedTypes={selectedTypes}
          handleTypeChange={handleTypeChange}
          clearFilters={clearFilters}
        />
        </div>
        <div id="resources" class="col-span-4 gap-4 grid mt-4 md:mt-0">
      <ProductsList products={filteredProducts} handlePurchase={handlePurchase} />
    </div>
    </div>

    </section>
  )}
  </div>
  );
};

const FilterPanel = ({ searchText, setSearchText, owned, setOwned, productTypes, selectedTypes, handleTypeChange, clearFilters }) => {
  return (
      <div>
        <div className="mb-4">
          <input
            className="w-full px-3 py-2 border-2 border-gray-200 rounded-md"
            type="text"
            placeholder="Search here..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center mb-4">
          <span className="text-lg font-semibold">Category</span>
          <button
            className="text-indigo-600 hover:text-indigo-800"
            onClick={clearFilters}
          >
            Clear
          </button>
        </div>
        <div className="flex flex-col gap-2">
          {[...productTypes].map((type, index) => (
            <div className="inline-flex items-center" key={type}>
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-indigo-600"
                checked={selectedTypes.has(type)}
                onChange={(e) => handleTypeChange(type, e.target.checked)}
                id={`checkbox-${index}`}
              />
              <label className="ml-2" htmlFor={`checkbox-${index}`}>
                {type}
              </label>
            </div>
          ))}
        </div>
      </div>
  );
};


const ProductsList = ({ products, handlePurchase }) => {
  if (!Array.isArray(products)) {
    return <div>Loading...</div>;
  }

  return (
    <div id="resources" className="col-span-4 gap-4 grid grid-cols-1 md:grid-cols-3">
      {products.map((product) => (
        <div key={product.recordID} className="resource col-span-1 flex flex-col p-4 bg-white gap-4 mx-2 md:mx-0 rounded-xl">
          <div className="flex">
            <div id="resourceType" className={`rounded-xl py-2 px-4 uppercase *: ${
          product.type === 'Workshop' ? 'bg-blue-100' :
          product.type === 'Template' ? 'bg-green-100' :
          product.type === 'Product' ? 'bg-red-100' :
          product.type === 'Guide' ? 'bg-purple-100' :
          product.type === 'Replay' ? 'bg-orange-100' :
          'bg-slate-100'
          }`}>
              {product.type}
            </div>
            <div className="flex-grow"></div>
          </div>
          <div id="resourceFilename" className="text-xl font-serif">
            {product.filename}
          </div>
          <div id="resourceSummary" className="text-gray-900 flex-grow">
            {product.summary}
          </div>
          {product.allowed ? (
            product.soon ? (
              <div className="about-tag-span blue">Coming soon</div>
            ) : (
              <a className="bg-slate-500 py-3 px-5 w-full rounded-xl font-bold font-serif text-white uppercase text-center hover:bg-orange-600"
              href={product.url} target="_blank">
                <div id="CTA">{product.cta}</div>
              </a>
            )
          ) : (
            <button
              type="submit"
              className="bg-slate-500 py-3 px-5 w-full rounded-xl font-bold font-serif text-white uppercase text-center hover:bg-orange-400"
              onClick={() => handlePurchase(product)}
            >
              <div id="CTA">{product.cta}</div>
            </button>
          )}
        </div>
      ))}
    </div>
  );
};


export default withGlobalState(ProductsPage);

import React, { useState, useEffect } from 'react';
import { ScrollArea, ScrollBar } from "./components/ui/scroll-area"
import {Button} from "./components/ui/button"
import { Toaster } from "./components/ui/toaster"
import { useToast } from "./components/ui/use-toast"
import Info from "./components/ui/info"
import InvestmentSimulation from './components/CBTestPortfolio'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "./components/ui/drawer"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs"


function App() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState(new Set());
  const [selectedSector, setSelectedSector] = useState(new Set());
  const industryKeys = Array.from(selectedIndustry).join(',');
  const sectorKeys = Array.from(selectedSector).join(',');
  const [cagrRange, setCagrRange] = useState({ '5yr': [0, 100], '10yr': [0, 50], '15yr': [0, 50] });
  const [betaRange, setBetaRange] = useState({ min: 0, max: 2 }); // Example range, adjust as needed
  const [stdDevRange, setStdDevRange] = useState({ min: 0, max: 1 }); // Example range, adjust as needed
  const [sortField, setSortField] = useState('name'); // Default sorting by name
  const [sortDirection, setSortDirection] = useState('asc'); // or 'desc'
  const { industries, sectors } = getUniqueIndustriesAndSectors(data);
  const [portfolio, setPortfolio] = useState([]);
  const { toast } = useToast()
  const [lastSaveTime, setLastSaveTime] = useState(Date.now());



  useEffect(() => {
    fetch('/my/api/crystal-ball')
      .then(response => {
        if (response.status === 401) {
          window.location.href = '/my/resources'; // Redirect if unauthorized
          throw new Error('Unauthorized'); // Prevent further then() blocks from executing
        }
        return response.json(); // Continue with JSON parsing only if authorized
      })
      .then(json => {
        console.log(json); // Log the JSON response
        setData(Object.values(json)); // Assuming json is an object where you want its values in an array
      })
      .then(() => {
        setFilteredData(data => data.slice(0, 30)); // Use a function within setState to directly use the updated state
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  

//Get portfolio
useEffect(() => {
  fetch('/my/api/get-portfolio')
  .then(response => response.json())
  .then(json => {
    setPortfolio(json)
  })
  .catch(error => console.error('Error fetching data:', error));
}, []);

const sortData = (data) => {
  const sortedData = [...data].sort((a, b) => {
    const valueA = typeof a[sortField] === 'string' ? a[sortField].toLowerCase() : a[sortField];
    const valueB = typeof b[sortField] === 'string' ? b[sortField].toLowerCase() : b[sortField];

    if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
    if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });
  return sortedData;
};

useEffect(() => {
  let result = data
  .filter(item => 
    item.name.toLowerCase().includes(searchText.toLowerCase()) || item.symbol.toLowerCase().includes(searchText.toLowerCase())
  )
  .filter(item => 
    selectedIndustry.size === 0 || selectedIndustry.has(item.industry)
  )
  .filter(item => 
    selectedSector.size === 0 || selectedSector.has(item.sector)
  )
  .filter(item => 
    (cagrRange['5yr'][0] === null || item.yr_5_cagr >= cagrRange['5yr'][0]) && 
    (cagrRange['5yr'][1] === null || item.yr_5_cagr <= cagrRange['5yr'][1]) &&
    (cagrRange['10yr'][0] === null || item.yr_10_cagr >= cagrRange['10yr'][0]) && 
    (cagrRange['10yr'][1] === null || item.yr_10_cagr <= cagrRange['10yr'][1]) &&
    (cagrRange['15yr'][0] === null || item.yr_15_cagr >= cagrRange['15yr'][0]) && 
    (cagrRange['15yr'][1] === null || item.yr_15_cagr <= cagrRange['15yr'][1])
  )
  .filter(item => 
    (betaRange.min === null || item.beta >= betaRange.min) && 
    (betaRange.max === null || item.beta <= betaRange.max)
  )
  .filter(item => 
    (stdDevRange.min === null || item.std_dev >= stdDevRange.min) && 
    (stdDevRange.max === null || item.std_dev <= stdDevRange.max)
  );

  result = sortData(result);

  setFilteredData(result.slice(0, 30));
}, [data, searchText, industryKeys, sectorKeys, cagrRange, betaRange, stdDevRange, sortField, sortDirection]);

const handleSortChange = (field) => {
  if (sortField === field) {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  } else {
    setSortField(field);
    setSortDirection('asc');
  }
};

const renderSortIndicator = (field) => {
  if (sortField !== field) return ''; // No indicator if not the current sort field
  return sortDirection === 'asc' ? ' 🔼' : ' ⬇️';
};

const getCurrentFiltersDescription = () => {
  const filters = [];

  if (searchText) filters.push(`Search: ${searchText}`);
  if (selectedIndustry.size) filters.push(`Industries: ${Array.from(selectedIndustry).join(', ')}`);
  if (selectedSector.size) filters.push(`Sectors: ${Array.from(selectedSector).join(', ')}`);
  if (cagrRange['5yr'].some(value => value !== null)) filters.push(`5yr CAGR: ${cagrRange['5yr'].join(' to ')}`);
  if (cagrRange['10yr'].some(value => value !== null)) filters.push(`10yr CAGR: ${cagrRange['10yr'].join(' to ')}`);
  if (cagrRange['15yr'].some(value => value !== null)) filters.push(`15yr CAGR: ${cagrRange['15yr'].join(' to ')}`);
  if (betaRange.min !== null || betaRange.max !== null) filters.push(`Beta: ${betaRange.min ?? 'min'} to ${betaRange.max ?? 'max'}`);
  if (stdDevRange.min !== null || stdDevRange.max !== null) filters.push(`Volatility: ${stdDevRange.min ?? 'min'} to ${stdDevRange.max ?? 'max'}`);

  return filters.join(', ');
};

const clearFilters = () => {
  setSearchText('');
  setSelectedIndustry(new Set());
  setSelectedSector(new Set());
  setCagrRange({ '5yr': [null, null], '10yr': [null, null], '15yr': [null, null] });
  setBetaRange({ min: null, max: null });
  setStdDevRange({ min: null, max: null });
};

function getUniqueIndustriesAndSectors(data) {
  const industries = new Set();
  const sectors = new Set();

  data.forEach(item => {
    if (item.industry) industries.add(item.industry);
    if (item.sector) sectors.add(item.sector);
  });

  // Convert Sets back to arrays for easier use later on (optional)
  return {
    industries: Array.from(industries),
    sectors: Array.from(sectors)
  };
}

const formatAsPercentage = (value) => {
  // Check if value is null, undefined, or 0
  if (value === null || value === undefined || value === 0) {
    return "-";
  }
  return `${(value * 100).toFixed(2)}%`; // Converts to percentage and keeps two decimal places
};

const togglePortfolioItem = (item) => {
  const isItemInPortfolio = portfolio.some(portfolioItem => portfolioItem.symbol === item.symbol);
  if (isItemInPortfolio) {
    // Remove the item from the portfolio
    setPortfolio(portfolio.filter(portfolioItem => portfolioItem.symbol !== item.symbol));
    toast({
      title: `${item.name} was removed from your portfolio`,
    })
  } else if (portfolio.length < 10) {
    // Add the item to the portfolio, ensuring it doesn't exceed 10 items
    setPortfolio([...portfolio, item]);
    toast({
      title: `${item.name} was added from your portfolio`,
      description: "Click on 'My Portfolio' to allocate your monthly investment",
    })
  } else {
    toast({
      title: `🚨 You have maxed out on your portfolio`,
      description: `${item.name} was not added as you are full. Click on 'My Portfolio' to delete the items you no longer want`,
    })
  }
};

const isItemInPortfolio = (symbol) => {
  return portfolio.some(item => item.symbol === symbol);
};

const addItemToPortfolio = (item) => {
  if (!isItemInPortfolio(item.symbol) && portfolio.length < 10) {
    setPortfolio([...portfolio, { ...item, monthlyInvestment: 0 }]); // Add item with default monthly investment
  }
};

const handleMonthlyInvestmentChange = (symbol, investment) => {
  setPortfolio(portfolio.map(item => {
    if (item.symbol === symbol) {
      return { ...item, monthlyInvestment: investment };
    }
    return item;
  }));
};

const renderPortfolioTable = () => {
  return (
    <table>
      <thead>
        <tr className='*:text-center'>
          <th>Ticker</th>
          <th>Name</th>
          <th>Annual Investment <Info infoText='How much do you invest in it each year?'/></th>
          <th>15 Year CAGR <Info infoText='How much it compounded each year for the past 15 years'/></th>
          <th>10 Year CAGR <Info infoText='How much it compounded each year for the past 10 years'/></th>
          <th>5 Year CAGR <Info infoText='How much it compounded each year for the past 5 years'/></th>
          <th>Average Return <Info infoText='The average compounded return since the stock existed. Note that average is less helpful than CAGR'/></th>
          <th>Beta <Info infoText='How correlated the stock is to the S&P500 (1 means it moves just like the S&P500)'/></th>
          <th>Volatility <Info infoText='How extreme the returns can be year over year. The lower it is, the closer to the average return you can expect it to be'/></th>
          <th>Action</th> {/* You can modify this to "Remove from Portfolio" */}
        </tr>
      </thead>
      <tbody>
        {portfolio.map((item, index) => (
          <tr key={index} className='*:text-center'>
            <td>{item.symbol}</td>
            <td>{item.name}</td>
            <td>
              <input
                type="number"
                className='border px-2 rounded-2xl'
                value={item.monthlyInvestment}
                onChange={(e) => handleMonthlyInvestmentChange(item.symbol, Math.max(0, parseInt(e.target.value, 10) || 0))}
                min="0"
              />
            </td>
            <td>{formatAsPercentage(item.yr_15_cagr)}</td>
            <td>{formatAsPercentage(item.yr_10_cagr)}</td>
            <td>{formatAsPercentage(item.yr_5_cagr)}</td>
            <td>{formatAsPercentage(item.average)}</td>
            <td>{item.beta}</td>
            <td>{formatAsPercentage(item.std_dev)}</td>
            <td>
                <button onClick={() => togglePortfolioItem(item)}>
                  {isItemInPortfolio(item.symbol) ? 'Remove' : 'Add'}
                </button>
              </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const savePortfolio = async () => {
  try {
    const response = await fetch('/my/api/save-portfolio', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(portfolio),
    });

    if (!response.ok) {
      toast({
        title: `🚨 Something went wrong`,
        description: `Something went wrong and we are unable to save your portfolio. Please take a screenshot of your portfolio and send us a message.`,
      })
      throw new Error('Network response was not ok');
    }

    const data = await response.json(); // Assuming your server responds with JSON
    // Handle success
    toast({
      title: `✅ Portfolio saved successfully`,
      description: `You will be able to use this portfolio the next time you log in.`,
    })
    setLastSaveTime(Date.now());
  } catch (error) {
    toast({
      title: `🚨 Error`,
      description: `${error}`,
    })
    console.error('Error saving portfolio:', error);
  }
};

const FindInvestments = () => {
  return (
  <div>
    <div className='flex flex-row gap-6 my-4 mx-4 justify-between'>
      <Drawer>
        <DrawerTrigger className='bg-purple-500 text-white rounded-2xl px-4'>Search & Filter</DrawerTrigger>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle className='text-center'>Find your ideal company and ETF</DrawerTitle>
              <div className='flex flex-row gap-6 my-4'>
              <input type="text" className="w-full px-3 py-2 border-2 border-gray-200 rounded-md" value={searchText} onChange={e => setSearchText(e.target.value)} placeholder='Search Comapny ticker or name'/>
              <input placeholder='Current Filters' type="text" disabled value={getCurrentFiltersDescription()} 
              className='text-xs bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2'/>
              <button onClick={clearFilters} className='bg-purple-500 text-white rounded-2xl px-4'>Clear</button>
              </div>
            <div className='flex flex-col md:flex-row justify-start md:justify-between'>
              <div>
              <h3 className='text-center'>Filter by Industry</h3>
              <ScrollArea className="h-[200px] w-[350px] rounded-md border p-4">
        {Array.from(industries).sort().map(industry => (
  <div key={industry}>
    <input
      type="checkbox"
      checked={selectedIndustry.has(industry)}
      onChange={e => {
        const newSet = new Set(selectedIndustry);
        if (e.target.checked) newSet.add(industry);
        else newSet.delete(industry);
        setSelectedIndustry(newSet);
      }}
    />
    {industry}
  </div>
      ))}
            </ScrollArea>
              </div>
              <div>
              <h3 className='text-center'>Filter by Sector</h3>
            <ScrollArea className="h-[200px] w-[350px] rounded-md border p-4">
            {Array.from(sectors).sort().map(sector => (
              <div key={sector}>
                <input
                  type="checkbox"
                  checked={selectedSector.has(sector)}
                  onChange={e => {
                    const newSet = new Set(selectedSector);
                    if (e.target.checked) newSet.add(sector);
                    else newSet.delete(sector);
                    setSelectedSector(newSet);
                  }}
                />
                {sector}
              </div>
            ))}
            </ScrollArea>
            </div>
            <div className='w-1/2 px-8'>
  <h3 className='text-center'>Other Filters</h3>
  <div className='grid grid-cols-5 gap-4'>
    <label className='col-span-2'>Beta Range:</label>
    <input type="number" className='border px-2 w-24 rounded-2xl text-sm' value={betaRange.min ?? ''} onChange={e => setBetaRange({ ...betaRange, min: e.target.value ? parseFloat(e.target.value) : null })} />
    <p>to</p>
    <input type="number" className='border px-2 w-24 rounded-2xl text-sm' value={betaRange.max ?? ''} onChange={e => setBetaRange({ ...betaRange, max: e.target.value ? parseFloat(e.target.value) : null })} />
  </div>

  <div className='grid grid-cols-5 gap-4'>
    <label className='col-span-2'>Volatility Range:</label>
    <input type="number" className='border px-2 w-24 rounded-2xl text-sm' value={stdDevRange.min ?? ''} onChange={e => setStdDevRange({ ...stdDevRange, min: e.target.value ? parseFloat(e.target.value) : null })} />
    <p>to</p>
    <input type="number" className='border px-2 w-24 rounded-2xl text-sm' value={stdDevRange.max ?? ''} onChange={e => setStdDevRange({ ...stdDevRange, max: e.target.value ? parseFloat(e.target.value) : null })} />
  </div>

  <div className='grid grid-cols-5 gap-4'>
    <label className='col-span-2'>5 Year CAGR Range:</label>
    <input 
      type="number" 
      className='border px-2 w-24 rounded-2xl text-sm'
      value={cagrRange['5yr'][0] ?? ''}
      onChange={e => setCagrRange({ ...cagrRange, '5yr': [e.target.value ? parseFloat(e.target.value) : null, cagrRange['5yr'][1]] })} 
    />
    <p>to</p>
    <input 
      type="number" 
      className='border px-2 w-24 rounded-2xl text-sm'
      value={cagrRange['5yr'][1] ?? ''} 
      onChange={e => setCagrRange({ ...cagrRange, '5yr': [cagrRange['5yr'][0], e.target.value ? parseFloat(e.target.value) : null] })} 
    />
  </div>

  <div className='grid grid-cols-5 gap-4'>
    <label className='col-span-2'>10 Year CAGR Range:</label>
    <input 
      type="number" 
      className='border px-2 w-24 rounded-2xl text-sm'
      value={cagrRange['10yr'][0] ?? ''}
      onChange={e => setCagrRange({ ...cagrRange, '10yr': [e.target.value ? parseFloat(e.target.value) : null, cagrRange['10yr'][1]] })} 
    />
    to
    <input 
      type="number" 
      className='border px-2 w-24 rounded-2xl text-sm'
      value={cagrRange['10yr'][1] ?? ''} 
      onChange={e => setCagrRange({ ...cagrRange, '10yr': [cagrRange['10yr'][0], e.target.value ? parseFloat(e.target.value) : null] })} 
    />
  </div>

  <div className='grid grid-cols-5 gap-4'>
    <label className='col-span-2'>15 Year CAGR Range:</label>
    <input 
      type="number" 
      className='border px-2 w-24 rounded-2xl text-sm'
      value={cagrRange['15yr'][0] ?? ''} 
      onChange={e => setCagrRange({ ...cagrRange, '15yr': [e.target.value ? parseFloat(e.target.value) : null, cagrRange['15yr'][1]] })} 
    />
    <p>to</p>
    <input 
      type="number" 
      className='border px-2 w-24 rounded-2xl text-sm'
      value={cagrRange['15yr'][1] ?? ''} 
      onChange={e => setCagrRange({ ...cagrRange, '15yr': [cagrRange['15yr'][0], e.target.value ? parseFloat(e.target.value) : null] })} 
    />
  </div>
</div>

            </div>
          </DrawerHeader>
          <DrawerFooter>
            <DrawerClose>
              <Button variant="outline">Close</Button>
            </DrawerClose>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
              <input type="text" placeholder='Current Filters' disabled value={getCurrentFiltersDescription()} 
              className='text-xs bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2'/>
              <button onClick={clearFilters} className='bg-purple-500 text-white rounded-2xl px-4'>Clear</button>
              </div>
      
      

      <table>
        <thead>
        <tr className='*:px-8'>
            <th onClick={() => handleSortChange('symbol')}>Ticker{renderSortIndicator('symbol')}</th>
            <th onClick={() => handleSortChange('name')}>Name{renderSortIndicator('name')}</th>
            <th onClick={() => handleSortChange('yr_15_cagr')}>15 Year CAGR <Info infoText='How much it compounded each year for the past 15 years'/>{renderSortIndicator('yr_15_cagr')}</th>
            <th onClick={() => handleSortChange('yr_10_cagr')}>10 Year CAGR <Info infoText='How much it compounded each year for the past 10 years'/>{renderSortIndicator('yr_10_cagr')}</th>
            <th onClick={() => handleSortChange('yr_5_cagr')}>5 Year CAGR <Info infoText='How much it compounded each year for the past 5 years'/>{renderSortIndicator('yr_5_cagr')}</th>
            <th onClick={() => handleSortChange('average')}>Average Return <Info infoText='The average compounded return since the stock existed. Note that average is less helpful than CAGR'/>{renderSortIndicator('average')}</th>
            <th onClick={() => handleSortChange('beta')}>Beta <Info infoText='How correlated the stock is to the S&P500 (1 means it moves just like the S&P500)'/>{renderSortIndicator('beta')}</th>
            <th onClick={() => handleSortChange('std_dev')}>Volatility <Info infoText='How extreme the returns can be year over year. The lower it is, the closer to the average return you can expect it to be'/>{renderSortIndicator('std_dev')}</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index} className='text-center odd:bg-white even:bg-slate-200'>
              <td>{item.symbol}</td>
              <td className='text-start'>{item.name} <Info infoText={item.summary}/></td>
              <td>{formatAsPercentage(item.yr_15_cagr)}</td>
              <td>{formatAsPercentage(item.yr_10_cagr)}</td>
              <td>{formatAsPercentage(item.yr_5_cagr)}</td>
              <td>{formatAsPercentage(item.average)}</td>
              <td>{item.beta}</td>
              <td>{formatAsPercentage(item.std_dev)}</td>
              <td>
                <button onClick={() => togglePortfolioItem(item)}>
                  {isItemInPortfolio(item.symbol) ? 'Remove' : 'Add'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
  </div>
  )
}
  

  return (
    <div>
      <Tabs defaultValue="find-investments" className="w-full hidden md:block">
        <TabsList className='flex flex-col md:flex-row gap-4'>
          <TabsTrigger value="find-investments">Find Investments</TabsTrigger>
          <TabsTrigger value="portfolio"><Drawer>
            <DrawerTrigger>My Portfolio</DrawerTrigger>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle><center>My portfolio</center></DrawerTitle>
                {renderPortfolioTable()}
              </DrawerHeader>
              <DrawerFooter>
              <Button onClick={savePortfolio}>Save Portfolio (will reset the test activity)</Button>
                <DrawerClose>
                  <Button variant="outline">Cancel</Button>
                </DrawerClose>
              </DrawerFooter>
            </DrawerContent>
          </Drawer></TabsTrigger>
          <TabsTrigger value="single-run">Test My Portfolio</TabsTrigger>
        </TabsList>
        <TabsContent value="single-run"><InvestmentSimulation lastSaveTime={lastSaveTime} /></TabsContent>
        <TabsContent value="portfolio"><FindInvestments /></TabsContent>
        <TabsContent value="find-investments"><FindInvestments /></TabsContent>

      </Tabs>
      <Toaster />
  </div>
  );
}

export default App;

import React, { useState } from 'react';
import { GlobalContext } from './GlobalState';
import Resume from './Resume';
import CoverLetter from './CoverLetter';
// import InterviewSection from './InterviewSection';

function CareerPage() {
  const [activeSection, setActiveSection] = useState('');

  const renderSection = () => {
    switch (activeSection) {
      case 'resume':
        return <Resume />;
      case 'coverLetter':
        return <CoverLetter />;
      // case 'interview':
      //   return <InterviewSection />;
      default:
        return <h1 className='text-2xl text-center'>Select an option to get started with your career development.</h1>;
    }
  };

  return (
    <div className="bg-purple-50 h-screen">
      <div className="flex justify-center space-x-4 p-4">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => setActiveSection('resume')}>Resume Feedback</button>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => setActiveSection('coverLetter')}>Cover Letter</button>
        {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => setActiveSection('interview')}>Practice Interview</button> */}
      </div>

      <div className="content mx-4">
        {renderSection()}
      </div>
    </div>
  );
}

export default CareerPage;

import React, { useState, useEffect } from 'react';
import { ScrollArea, ScrollBar } from "./components/ui/scroll-area"
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./components/ui/table"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./components/ui/accordion"
import RoboCalculator from './components/RoboCalculator';
import Retirement from './components/Retirement';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs"


function App() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('/my/api/robo')
      .then(response => response.json())
      .then(json => {
        const parsedData = json.map(entry => {
          try {
            console.log(entry[0]);
            return JSON.parse(entry[0]);
          } catch (error) {
            console.error('Error parsing JSON:', error);
            return null;
          }
        }).filter(entry => entry !== null); // Filter out any null entries
        setData(parsedData);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="mx-4 max-w-4xl h-screen md:mx-auto mt-8 space-y-4">
      <div className='font-serif text-4xl font-bold text-center'>Ultimate Robo Advisor Guide</div>
      <div className='text-start'>This is meant to give you all the information you can potentially want in order to make an informed decision as to whether robo advisors are a good fit for you.</div>
      <div>This page is a work in progress so if we're missing a product, or a robo advisor, or you want to see something else included,<a href="https://club.sav.finance/" className='text-green-700 font-bold'> tell us on sav club ↗</a></div>

      <Accordion type="single" collapsible className='block md:hidden'>
      <AccordionItem value="robo">
        <AccordionTrigger>List of Robo Advisor Investment Options</AccordionTrigger>
        <AccordionContent><Robo data={data} /></AccordionContent>
      </AccordionItem>
      <AccordionItem value="calculate">
        <AccordionTrigger>Test My Portfolio</AccordionTrigger>
        <AccordionContent><RoboCalculator/></AccordionContent>
      </AccordionItem>
      <AccordionItem value="retirement">
        <AccordionTrigger>Retirement Calculator</AccordionTrigger>
        <AccordionContent><Retirement/></AccordionContent>
      </AccordionItem>
    </Accordion>


      <Tabs defaultValue="robo" className="w-full hidden md:block">
        <TabsList className='flex flex-col md:flex-row gap-4'>
          <TabsTrigger value="robo">List of Robo Advisor Investment Options</TabsTrigger>
          <TabsTrigger value="calculate">Test My Portfolio</TabsTrigger>
          <TabsTrigger value="retirement">Retirement Calculator</TabsTrigger>
        </TabsList>
        <TabsContent value="robo"><Robo data={data} /></TabsContent>
        <TabsContent value="calculate"><RoboCalculator/></TabsContent>
        <TabsContent value="retirement"><Retirement/></TabsContent>
      </Tabs>
      {/* <RoboCalculator/> */}
      {/* <Robo data={data} /> */}
    </div>
  );
}

const Robo = (data) => {
  const dataArray = data.data;
  const orderedKeys = ['Company', 'Product', 'Ideal Horizon','10 Years Return (Annualised)', '5 Years Return (Annualised)', '3 Years Return (Annualised)', '1 Year Return', 
   '2014', '2015', 
  '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', 'Std Dev'];
  const percentageKeys = ['1 Year Return', '3 Years Return (Annualised)', 
  '5 Years Return (Annualised)', '10 Years Return (Annualised)', '2014', '2015', 
  '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', 'Std Dev']
  const [sortedData, setSortedData] = useState(data.data || []);
  const [sortConfig, setSortConfig] = useState({ key: orderedKeys[0], direction: 'ascending' });
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [yearReturnFilters, setYearReturnFilters] = useState({
    tenYear: { text: '10 year return', min: null, max: null },
    fiveYear: { text: '5 year return', min: null, max: null },
    threeYear: { text: 'last 3 year annualised return',min: null, max: null },
    oneYear: { text: 'last year return',min: null, max: null },
  });

  useEffect(() => {
    if (data && data.data) {
      const filteredData = data.data.filter(item => {
        const fields = item.fields;
        const companyMatch = fields['Company'].toLowerCase().includes(searchTerm.toLowerCase());
        const productMatch = fields['Product'].toLowerCase().includes(searchTerm.toLowerCase());
        const tenYearMatch = isInRange(fields['10 Years Return (Annualised)'], yearReturnFilters.tenYear);
        const fiveYearMatch = isInRange(fields['5 Years Return (Annualised)'], yearReturnFilters.fiveYear);
        const threeYearMatch = isInRange(fields['3 Years Return (Annualised)'], yearReturnFilters.threeYear);
        const oneYearMatch = isInRange(fields['1 Year Return'], yearReturnFilters.oneYear);

        return companyMatch && productMatch && tenYearMatch && fiveYearMatch && threeYearMatch && oneYearMatch;
      });
      sortData(sortConfig.key, sortConfig.direction, filteredData);
    }
  }, [data, searchTerm, yearReturnFilters, sortConfig]);

  const isInRange = (value, range) => {
    if (!value || (range.min === null && range.max === null)) return true;
    // Convert value to a decimal assuming it's a percentage
    const val = parseFloat(value);
    return (range.min === null || val >= range.min) && (range.max === null || val <= range.max);
  };


  useEffect(() => {
    if (data && data.data) {
      sortData(sortConfig.key, sortConfig.direction, data.data);
    }
  }, [data]); // Effect runs on component mount and whenever data changes

  if (!data || data.length === 0) {
    return <div>Loading...</div>;
  }

  const onSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    sortData(key, direction, data.data);
  };

  const sortData = (key, direction, dataToSort) => {
    const sorted = [...dataToSort].sort((a, b) => {
      const valueA = parseFloat(a.fields[key]) || 0;
      const valueB = parseFloat(b.fields[key]) || 0;
      if (valueA < valueB) return direction === 'ascending' ? -1 : 1;
      if (valueA > valueB) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
    setSortedData(sorted);
  };

  const handleMouseEnter = (key) => {
    setHoveredColumn(key);
  };

  const handleMouseLeave = () => {
    setHoveredColumn(null);
  };

  const formatAsPercentage = (number) => {
    return `${(number * 100).toFixed(2)}%`;
  };


  if (!data || data.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className='font-serif text-4xl font-bold text-center py-8'>Robo Advisor Historical Returns</div>
      <div className='text-start pb-2'>The return numbers were pulled from the robo advisor's website. We also tried to pull the annualised return but in cases wheret they were not publically shared, we calculated them ourselves.</div>
      <div className='pb-4'>Use the filters below or click on a column header to sort.</div>

      <div className='w-full gap-4 flex flex-col'>
        <input
          type="text"
          placeholder="Search company or product..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className=' border py-2 px-4 rounded-xl'
        />
        {/* Range inputs for each return type */}
        {Object.entries(yearReturnFilters).map(([key, filter]) => (
          <div className='grid grid-cols-3' key={key}>
            <p>{`${filter.text}`}</p>
            <input className=' px-2 py-1'
              type="number"
              placeholder={`Min`}
              onChange={(e) => setYearReturnFilters({
                ...yearReturnFilters,
                [key]: { ...filter, min: e.target.value ? parseFloat(e.target.value) : null },
              })}
            />
            <input
              type="number"
              placeholder={`Max`}
              onChange={(e) => setYearReturnFilters({
                ...yearReturnFilters,
                [key]: { ...filter, max: e.target.value ? parseFloat(e.target.value) : null },
              })}
            />
          </div>
        ))}
      </div>
      
    <ScrollArea className="w-full whitespace-nowrap md:rounded-2xl md:border">
    <Table>
  <TableCaption>A list robo advisor returns over time.</TableCaption>
  <TableHeader>
    <TableRow className='bg-green-50'>
      {orderedKeys.map(key => (
        <TableHead
          key={key}
          onMouseEnter={() => handleMouseEnter(key)}
          onMouseLeave={handleMouseLeave}
          onClick={() => onSort(key)}
          className={hoveredColumn === key ? 'bg-gray-300 cursor-pointer' : ''}
        >
          {key}
          {sortConfig.key === key && (sortConfig.direction === 'ascending' ? ' 🔼' : ' 🔽')}
        </TableHead>
      ))}
    </TableRow>
  </TableHeader>
  <TableBody>
    {sortedData.map((item, index) => (
      <TableRow
        key={index}
        className={index % 2 === 0 ? 'bg-gray-50' : ''}
      >
        {orderedKeys.map(key => (
          <TableCell
            key={key}
            className={hoveredColumn === key ? 'hover:bg-gray-100' : ''}
          >
            {item.fields && item.fields[key] !== undefined 
              ? (percentageKeys.includes(key) ? formatAsPercentage(item.fields[key]) : item.fields[key])
              : '-'}
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableBody>
</Table>

      <ScrollBar orientation="horizontal" />
      <ScrollBar orientation="vertical" />
    </ScrollArea>
    </div>
  );
};

export default App;

import React, { useEffect, useState, useRef } from 'react';
import Image from '../components/Image'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion"

const CollectionProducts = () => {
  return (
    <body className="bg-msc-bg">
      <div className='flex flex-col mx-4 md:mx-auto max-w-4xl py-8'>
        <h1 className='text-4xl md:text-6xl text-start md:text-center font-serif py-8'>Level Up Your Finances</h1>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-6 *:flex *:flex-col *:gap-2 *:border *:rounded-2xl *:p-4'>
          <a href="/products/money-planner" className='hover:bg-green-50'>
            <Image src='money-planner-cover-1x1.jpg' className='flex-none'/>
            <h2 className='text-2xl text-start'>Sav Money Planner</h2>
            <p className='grow'> </p>
            <p className='text-start'>Comes in <span className='underline'>both</span> Goole Sheet & PDF versions</p>
            <p className='text-start'>$17</p>
          </a>
          <a href="/lp/new-year" className='hover:bg-green-50'>
            <Image src='msc-hero.jpg' className='flex-none'/>
            <h2 className='text-2xl text-start'>Money Spring Cleaning 2024 Package</h2>
            <p className='grow'> </p>
            <p className='text-start'>Get access to all replays and bonuses associated with MSC 2024</p>
            <p className='text-start'>$20</p>
          </a>
          <a href="/products/robo-explorer" className='hover:bg-green-50'>
            <Image src='robo-explorer-1x1.jpg' className='flex-none'/>
            <h2 className='text-2xl text-start'>Robo+: Your Personal Robo-Explorer</h2>
            <p className='grow'> </p>
            <p className='text-start'>A must have for anyone investing using robo advisors</p>
            <p className='text-start'>$7</p>
          </a>
          <a href="/products/investing-course" className='hover:bg-green-50'>
            <Image src='investing-course-bonus-1x1.jpg' className='flex-none'/>
            <h2 className='text-2xl text-start'>Beginners Course to Investing</h2>
            <p className='grow'> </p>
            <p className='text-start'>*The* hands on investing course <span className='bg-purple-50'>made for beginners</span>. Explore your options, create your unique lifelong investment plan & get your passive income flowing.</p>
            <p className='text-start'>$199</p>
          </a>
        </div>
      </div>


    </body>
  

  );
}

export default CollectionProducts;
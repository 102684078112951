import React, { useEffect, useState, useRef } from 'react';
import Image from '../components/Image'
import ImageGrid from '../components/ImageGrid'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion"

const MoneyPlanner = () => {
  return (
  <div className="bg-msc-bg">
  <section className="py-6 dark:bg-gray-900 mx-4 md:mx-auto">
    <div className="mx-auto max-w-screen-lg">
      <div className="flex flex-col pb-8 gap-y-6">
        <h1
          className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-xl md:text-4xl dark:text-white text-balance uppercase">
          YOUR YEAR OF FINANCIAL TRANSFORMATION STARTS HERE
        </h1>
        <div className="grid gap-4">
          <ImageGrid urls={['money-planner/money-planner-large-1.jpg','money-planner/money-planner-large-2.jpg','money-planner/money-planner-large-3.jpg','money-planner/money-planner-large-4.jpg','money-planner/money-planner-large-5.jpg']}/>
        </div>
      </div>
      <div className="flex flex-col gap-8 items-center md:mx-auto max-w-screen-lg">
        <div className="flex flex-col py-2 gap-4 max-w-screen-lg">
          <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-xl md:text-4xl dark:text-white text-balance">Get Your Sh*t Together in 2024</h2>
          <p className="mb-6 text-gray-500 dark:text-gray-400">*Actually* develop good money habits with the help of sav’s <b>Money Mindset Digital Planner</b>. More than a budgeting spreadsheet, our planner gives you a <b>360° view of your personal finances.</b></p>
          <h3 className="fontabold tracking-tight leading-none text-gray-900 text-lg dark:text-white text-balance">
            This planner will help you</h3>
          <ul className="text-gray-500 dark:text-gray-400" >
            <li>Keep track of your money</li>
            <li>Get clear on your financial goals</li>
            <li>Cultivate a positive money mindset</li>
            <li>Uncover and dissolve the obstacles blocking you from financial health</li>
          </ul>
        </div>
      </div>
    </div>
    <div className="flex flex-col justify-items-center max-w-xl mx-4 md:mx-auto gap-y-2 py-8">
          <a href='https://cart.sav.finance/sav-money-mindset-planner-2024/' className='bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 w-full text-center rounded-2xl text-2xl md:text-4xl'>Buy Now ($17)</a>
        </div>
      
      
    <div className="mx-auto max-w-screen-lg py-8 space-y-4">
      <div className="flex flex-col gap-y-2">
          <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-xl md:text-4xl dark:text-white text-balance capitalize">Have a Peek Inside👇</h2>
          <p className="mb-6 text-gray-500 dark:text-gray-400 text-start md:text-center">*106 pages designed to get your $$ in shape 
            + exclusive stickers to keep it cute 🐶✨</p>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:gap-8 bg-white py-8 mx-4 md:mx-auto max-w-screen-lg xl:gap-16 sm:py-8 px-4 lg:px-6 rounded-3xl border-2 items-center">
          <div className="order-1 md:order-last">
            <Image src='money-planner/money-planner-large-2.jpg' />
          </div>
          <div className="order-2 py-2">
            <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-xl dark:text-white text-balance capitalize pb-4">
            Net worth Tracker
            </h2>
            <p className="text-gray-500 dark:text-gray-400">Knowing your net worth is like finding a guiding star in the night sky, providing direction and confidence in your financial decisions and future aspirations.</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:gap-8 bg-white py-8 mx-4 md:mx-auto max-w-screen-lg xl:gap-16 sm:py-8  px-4 lg:px-6 rounded-3xl border-2 items-center">
          <div className="order-1">
            <Image src='money-planner/money-planner-large-3.jpg'/>
          </div>
          <div className="order-2 py-2">
            <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-xl dark:text-white text-balance capitalize pb-4">
              Monthly Budget
            </h2>
            <p className="text-gray-500 dark:text-gray-400">Embrace the calm that comes with knowing exactly where your money goes each month. Use the monthly budget and get yourself a sense of control and peace.</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:gap-8 bg-white py-8 mx-4 md:mx-auto max-w-screen-lg xl:gap-16 sm:py-8  px-4 lg:px-6 rounded-3xl border-2 items-center">
          <div className="order-1 md:order-last">
            <Image src='money-planner/money-planner-large-4.jpg'/>
          </div>
          <div className="order-2 py-2">
            <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-xl dark:text-white text-balance capitalize pb-4">
              Monthly Overview
            </h2>
            <p className="text-gray-500 dark:text-gray-400">Set a monthly intention and stay on top of your commitments with this handy monthly overview.</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:gap-8 bg-white py-8 mx-4 md:mx-auto max-w-screen-lg xl:gap-16 sm:py-8  px-4 lg:px-6 rounded-3xl border-2 items-center">
          <div className="order-1">
            <Image src='money-planner/money-planner-large-5.jpg'/>
          </div>
          <div className="order-2 py-2">
            <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-xl dark:text-white text-balance capitalize pb-4">
              Weekly Planner
            </h2>
            <p className="text-gray-500 dark:text-gray-400">Besides tracking your to-dos, you can also track your daily expenses (and maybe even do your own money diary!)</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:gap-8 bg-white py-8 mx-4 md:mx-auto max-w-screen-lg xl:gap-16 sm:py-8  px-4 lg:px-6 rounded-3xl border-2 items-center">
          <div className="order-1 md:order-last">
            <Image src='money-planner/money-planner-large-6.jpg'/>
          </div>
          <div className="order-2 py-2">
            <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-xl dark:text-white text-balance capitalize pb-4">
              7 Money Challenges
            </h2>
            <p className="text-gray-500 dark:text-gray-400">Complete these 7 challenges and you're guaranteed to have a transformative year.</p>
          </div>
        </div>
        </div>
  </section>
  <section className="pb-12 dark:bg-gray-900 #effbff">
    <div className="px-4 mx-auto max-w-screen-lg">
      <div className="flex flex-col pb-8 gap-y-6">
      <h1 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-xl md:text-4xl dark:text-white text-balance uppercase">🎁 BONUS: Google Sheet version included</h1>
      <p className="text-gray-500 dark:text-gray-400 md:text-center">Just like the PDF version but with automation 👩🏻‍💻</p>
        <div className="grid gap-4 bg-white p-4 rounded-2xl">
        <ImageGrid urls={['money-planner/planner-sheet-1.jpg','money-planner/planner-sheet-2.jpg','money-planner/planner-sheet-3.jpg','money-planner/planner-sheet-4.jpg','money-planner/planner-sheet-5.jpg']}/>
        </div>
        </div>
        <div className="flex flex-col justify-items-center max-w-xl mx-4 md:mx-auto gap-y-2">
          <a href='https://cart.sav.finance/sav-money-mindset-planner-2024/' className='bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 w-full text-center rounded-2xl text-2xl md:text-4xl'>Buy Now ($17)</a>
        </div>
    </div>
    
  </section>
  <section id='faq' className=' mx-auto flex flex-col py-8'>
      <h1 className="text-center md:text-start font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance mx-auto">FAQ</h1>
      <Accordion type="single" collapsible className='max-w-2xl mx-auto px-4 w-full'>
        <AccordionItem value="item-1">
        <AccordionTrigger className='text-start text-pretty text-xl md:text-2xl font-bold'>How will I get my Digital Planner?</AccordionTrigger>
          <AccordionContent>
            <p className="mb-6 text-gray-500 text-lg dark:text-gray-400">We will send you an email with a link to download your digital planner. You can then import the digital planner into a secondary note-taking application or use the Google Sheet version with your Google account.</p>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-2">
        <AccordionTrigger className='text-start text-pretty text-xl md:text-2xl font-bold'>What devices are supported?</AccordionTrigger>
          <AccordionContent>
          <p className="text-gray-500 dark:text-gray-400">Our Digital Planner PDF version is designed to work with tablets that support a stylus. You can also open it on a Desktop PDF Editor app, but it is not optimized for use that way.<br/><br/>✦ iPad or any Android Tablets<br/>✦ Stylus e.g. Apple Pencil</p>
              <p className="text-gray-500 dark:text-gray-400">If you're using our Google Sheet version, then you can use it on any device so long as you connect it to your Google account!</p>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-3">
        <AccordionTrigger className='text-start text-pretty text-xl md:text-2xl font-bold'>What apps are supported?</AccordionTrigger>
          <AccordionContent>
          <p className="text-gray-500 dark:text-gray-400">Only PDF-compatible apps can support the full functionality of the Digital Planner. Some apps are free, while others might require a one-off payment or subscription. Our personal pick is GoodNotes!<br/><br/>✦ iOS for iPad: GoodNotes, Notability<br/>✦Android: Noteshelf or Xodo</p>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-4">
          <AccordionTrigger className='text-start text-pretty text-xl md:text-2xl font-bold'>Can I use the planner in other apps?
</AccordionTrigger>
          <AccordionContent>
          <p className="mb-6 text-gray-500 text-lg dark:text-gray-400">TYes, you can use it on your Desktop in a PDF Editor app or even go old-school and print it out. Please note that you won't be able to use the interactive PDF functions like navigating through the buttons if you're NOT using an app that supports hyperlink functions.</p>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-5">
          <AccordionTrigger className='text-start text-pretty text-xl md:text-2xl font-bold'>Can I get a refund?</AccordionTrigger>
          <AccordionContent>
          <p className="mb-6 text-gray-500 text-lg dark:text-gray-400">Sorry, all digital products are downloadable instantly and hence we won't be able to refund or exchange it for you. If you are having any trouble using the planner or if you have any feedback for us, do drop us an email at admin@sav.finance.</p>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      </section>
      <div className="flex flex-col justify-items-center max-w-xl mx-4 md:mx-auto gap-y-2 py-8">
          <a href='https://cart.sav.finance/sav-money-mindset-planner-2024/' className='bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 w-full text-center rounded-2xl text-2xl md:text-4xl'>Buy Now ($17)</a>
        </div>


  </div>
)
};

export default MoneyPlanner;
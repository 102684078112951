import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import TypeformEmbed from './components/TypeformEmbed';
import GIF from './components/GIF';
import Popup from './components/Popup';


const Profile = () => {
    const [data, setData] = useState(null);
    const [loading, setLoadingAdditional] = useState(true);
    const [errorAdditional, setErrorAdditional] = useState(null);
    const [popupContent, setPopupContent] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const success = (
      <div className='flex flex-col *:py-2'>
        <h1 className='text-4xl'>You got your credits!</h1>
        <GIF src='birthday.mp4' className='flex justify-center items-center h-1/4'/>
        <p className='text-gray-500 text-center'>Now go redeem them for resources!</p>
      </div>
    )
    const redeemed = (
      <div className='flex flex-col *:py-2'>
        <h1 className='text-4xl'>It seems like you've already redeemed</h1>
        <p className='text-gray-500 text-center'>If there's a mistake, DM us on Instagram @sav.finance</p>
      </div>
    )
  
    useEffect(() => {
      fetch(`/my/api/data`)
        .then(response => response.json())
        .then(data => {
          setData(data);
          setLoadingAdditional(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setErrorAdditional(error);
          setLoadingAdditional(false);
        });
    }, []);

    const handleButtonClick = () => {
      fetch('/my/api/birthday', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
      })
      .then(response => {
          if (!response.ok) {
              if (response.status === 400) {
                  setPopupContent(redeemed);
              }
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
          setData(data);
          setPopupContent(success);
      })
      .catch(error => {
          console.error('Error fetching data:', error);
          if (!popupContent) {
              setPopupContent(redeemed);
          }
      })
      .finally(() => {
          setShowPopup(true); // Show the popup regardless of success or error
      });
  };

  const handleClosePopup = () => {
      setShowPopup(false);
      setPopupContent(null);
  };
  
  
  
    if (errorAdditional) {
      return <div>Error: {errorAdditional.message}</div>;
    }

	return (
		<div className="App">
      <Popup 
                show={showPopup}
                content={popupContent}
                onClose={handleClosePopup}
            />
			<header className="My-Profile">
				{/* Calling a data from setdata for showing */}
        <section className=" dark:bg-gray-900 mx-auto flex flex-col items-center bg-slate-100">
    <div className="py-4 px-4 mx-auto max-w-screen-lg lg:px-12 sm:text-center p-4 md:p-8">
      <h1 className="font-serif text-4xl font-bold leading-none lg:text-5xl mb-8 text-center">Your Profile</h1>
      <div className="flex flex-col md:flex-row gap-8">
        <div className="bg-white p-4 rounded-xl">
        {!loading && data && (
          <h3 className="pb-6 text-center text-2xl">{data.fields?.['Credits']}</h3>
        )}
          <p className="font-bold text-center pb-2 text-xl">Credits Available</p>
          {!loading && data && (
          <p className>You have {data.fields?.['Paid Credits']} credits and {data.fields?.['Free Credits']} free credits. We will always use your free credits before we use your paid credits.</p>
          )}
        </div>
        <div className="bg-white p-4 rounded-xl">
        {!loading && data && (
          <h3 className="pb-6 text-center text-2xl">{data.fields?.['Daily Credits']}</h3>
        )}
          <p className="font-bold text-center text-xl pb-2">New Daily Credits</p>
          <p className>You will get this many free credits a day up to a maxmium of 15.</p>
        </div>
      </div>
      {false && (
      <div>
        <div className="bg-slate-500 p-4 rounded-xl mt-8">
          <h3 className="pb-6 text-center capitalize">Top Up Credits</h3>
          <p className="text-center">Credits are $1 each. Min $5.</p>
          <p className="text-center">25 Credits for $20</p>
          <p className="text-center">100 Credits for $50</p>
        </div>
      </div>
      )}
    </div>
        </section>

			</header>
		</div>
	);
}

export default Profile;

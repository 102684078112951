import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table"

import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from "./ui/command"


function RoboCalculator() {
  const [data, setData] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({ product1: '', product2: '', product3: '' });
  const [investmentAmounts, setInvestmentAmounts] = useState({ product1: 0, product2: 0, product3: 0 });
  const [results, setResults] = useState([]);
  const [productFields, setProductFields] = useState([]);
  const [totalReturn, setTotalReturn] = useState(0);
  const [annualizedReturn, setAnnualizedReturn] = useState(0);
  const productKeys = Object.keys(selectedProducts);
  const [totalProfit, setTotalProfit] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [twr, setTwr] = useState(0);
  const [productSelections, setProductSelections] = useState(
    Object.keys(selectedProducts).reduce((acc, productId) => {
      acc[productId] = null;
      return acc;
    }, {})
  );

  const handleProductSelect = (productId, selectedId) => {
    setProductSelections({ ...productSelections, [productId]: selectedId });
    setSelectedProducts({ ...selectedProducts, [productId]: selectedId });
  };



  const orderedKeys = ['Year'];
  

Object.keys(selectedProducts).forEach(productId => {
  if (selectedProducts[productId]) {
    const productName = productFields[selectedProducts[productId]]?.Name || 'Unknown Product';
    orderedKeys.push(`Return`);
    orderedKeys.push(`Ending Balance`);
  }
});

orderedKeys.push('Average Return', 'Total Ending Balance');



  useEffect(() => {
    fetch('/my/api/robo')
      .then(response => response.json())
      .then(json => {
        const fetchedData = json.map(entry => JSON.parse(entry[0]));
        setData(fetchedData);
        fetchedData.sort((a, b) => {
          if (a.fields.Name > b.fields.Name) return 1;
          if (a.fields.Name < b.fields.Name) return -1;
          return 0;
        });
        // Create a mapping from product ID to product fields
        const fieldsMapping = fetchedData.reduce((acc, product) => {
          acc[product.id] = product.fields;
          return acc;
        }, {});
        setProductFields(fieldsMapping); // Store the mapping in a state
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  

  const handleInvestmentAmount = (productId, amount) => {
    setInvestmentAmounts({ ...investmentAmounts, [productId]: amount });
  };

  // Define a function to calculate investment returns.
  const calculateReturns = () => {
    let yearResults = {};
    let productBalances = {}; // Initialize balances for each selected product
    let subPeriodReturns = []; // Store the returns of each sub-period


  
    // Initialize balances for selected products
    Object.keys(selectedProducts).forEach(productId => {
      if (selectedProducts[productId]) {
        productBalances[productId] = 0;
      }
    });
  
    // Loop through each year from 2014 to 2023
    for (let year = 2014; year <= 2023; year++) {
      let totalAnnualInvestment = Object.keys(selectedProducts).reduce((acc, productId) => {
        return acc + (parseFloat(investmentAmounts[productId]) || 0);
      }, 0);
  
      let totalBeginningBalance = (year === 2014) ? totalAnnualInvestment : Object.values(productBalances).reduce((acc, val) => acc + val, 0);
      let totalEndingBalance = 0;
  
      yearResults[year] = { year, products: {} };
  
      Object.keys(selectedProducts).forEach(productId => {
        const productID = selectedProducts[productId];
        if (!productID) return;
  
        const productData = data.find(p => p.id === productID);
        if (!productData) return;
  
        const annualInvestment = parseFloat(investmentAmounts[productId]) || 0;
        const yearReturn = typeof productData.fields[year.toString()] === 'number' 
                            ? productData.fields[year.toString()] 
                            : calculateEstimatedReturn(productData);
  
        const startingBalance = productBalances[productId] + annualInvestment;
        const endingBalance = startingBalance * (1 + yearReturn);
        productBalances[productId] = endingBalance;
  
        totalEndingBalance += endingBalance;
  
        yearResults[year].products[productId] = {
          return: (yearReturn * 100).toFixed(2),
          endingBalance: endingBalance.toFixed(2)
        };
        let totalReturnThisYear = totalEndingBalance - totalBeginningBalance;
        let subPeriodReturn = totalBeginningBalance > 0 ? (1 + totalReturnThisYear / totalBeginningBalance) : 1;
        subPeriodReturns.push(subPeriodReturn);
      });
  
      // Calculate the weighted average return for the year
      const weightedAverageReturn = totalBeginningBalance > 0 ? (totalEndingBalance - totalBeginningBalance) / totalBeginningBalance : 0;
      yearResults[year].weightedAverageReturn = (weightedAverageReturn * 100).toFixed(2); // Ensure it's in percentage format
      yearResults[year].totalEndingBalance = totalEndingBalance.toFixed(2);
      const twr = Math.pow(subPeriodReturns.reduce((acc, val) => acc * val, 1), 1 / subPeriodReturns.length) - 1;
      const twrPercentage = (twr * 100).toFixed(2);
      setTwr(twrPercentage);
    }

  // Update the state with the calculated year results.
  setResults(Object.values(yearResults));
  // Store the year results in local storage.
  localStorage.setItem('roboCalculatorResults', JSON.stringify(yearResults));

  // Calculate the total return, annualized return, and total profit over the period.
  const initialInvestment = Object.keys(investmentAmounts).reduce((acc, key) => acc + parseFloat(investmentAmounts[key]) * (2023 - 2014 + 1), 0);
  const finalBalance = yearResults["2023"].totalEndingBalance;
  const totalReturnPercentage = ((finalBalance - initialInvestment) / initialInvestment) * 100;
  const annualizedReturnPercentage = Math.pow(finalBalance / initialInvestment, 1 / (2023 - 2014)) - 1;
  const totalProfit = finalBalance - initialInvestment;

  // Update the state with the calculated total return, annualized return, and total profit.
  setTotalReturn(totalReturnPercentage.toFixed(2));
  setAnnualizedReturn((annualizedReturnPercentage * 100).toFixed(2));
  setTotalProfit(totalProfit.toFixed(2));
  setShowResults(true);
    };

  

  const calculateEstimatedReturn = (productData) => {
    const oldestAnnualisedReturn = productData.fields['3 Years Return (Annualised)'] || productData.fields['5 Years Return (Annualised)'];
    const stdDev = productData.fields['Std Dev'] || 0;
    return oldestAnnualisedReturn + (Math.random() - 0.5) * stdDev;
  };
  
  
  
  
  const handleProductChange = (productId, value) => {
    setSelectedProducts({ ...selectedProducts, [productId]: value });
  };

  return (
    <div>
      <div className='font-serif text-4xl font-bold text-center py-8'>Create & Test Your Portfolio</div>
      <div className='text-start px-8 pb-2'>Pick up to 3 robo advisor portfolio and see how you would have done if you started in 2014 until today</div>
      <div className='pb-4 px-8 text-sm'>Note: this is just to give you an idea of historical returns. This is in no way a prediction of what will happen in the future.</div>
    <div className=' mx-4 flex flex-col md:mx-auto max-w-2xl border rounded-xl py-8'>
      {/* Product Selection and Investment Input */}
      <div className='flex flex-col md:flex-row md: gap-16 md:justify-between w-full'>
      <div className='md:basis-1/2 pb-8'>
      <h3 className='text-2xl font-bold font-serif text-center border-b-2'>Product</h3>
      {productKeys.map((productId, index) => {
        // Check if the previous product has been selected (has an ID)
        const showComponent = index === 0 || selectedProducts[productKeys[index - 1]] !== '';

        return showComponent ? (
          <div key={productId}>
            {productSelections[productId] === null ? (
              // Display Command component when no product is selected
              <Command>
                <CommandInput placeholder="Search products" />
                <CommandList>
                  <CommandGroup heading="Products">
                    {data.map(product => (
                      <CommandItem 
                        key={product.id} 
                        onSelect={() => handleProductSelect(productId, product.id)}
                      >
                        {product.fields.Name}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            ) : (
              // Display select field when a product is selected
              <select 
                value={selectedProducts[productId]}
                onChange={(e) => handleProductChange(productId, e.target.value)}
                className='px-4 w-full my-2'
              >
                <option value="" disabled>Select a product</option>
                {data.map(product => (
                  <option key={product.id} value={product.id}>
                    {product.fields.Name}
                  </option>
                ))}
              </select>
            )}
          </div>
        ) : null;
      })}
      <p className='mx-2 md:mx-auto'>Choose up to 3 robo advisor portfolios you would like to create as a test portfolio</p>
    </div>

    <div className='md:basis-1/2 w-full'>
  <h3 className='text-2xl font-bold font-serif text-center border-b-2'>Annual Investment</h3>
  {Object.keys(selectedProducts).map((productId) => {
    // Check if the product ID in selectedProducts is not empty
    if (selectedProducts[productId] !== '') {
      return (
        <input
          key={productId}
          type="number"
          placeholder="Annual Investment"
          value={investmentAmounts[productId]}
          onChange={(e) => handleInvestmentAmount(productId, e.target.value)}
          className='px-4 text-center w-full py-2' // Adjust this as needed for layout
        />
      );
    }
    return null; // If the product ID is empty, do not render the input field
  })}
  <p className='mx-2 md:mx-auto'>How much would you be able to put into each of the 3 products above? This is an annual number.</p>
</div>

        
      </div>

      {/* Calculate Button */}
      <button onClick={calculateReturns} className='my-8 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 mx-2 md:mx-auto'>Calculate My Return</button>

      {/* Results Table */}
      {showResults && (
      <Table>
  <TableHeader>
  <TableRow className="border-y-0">
    <TableHead colSpan={1}></TableHead>
    {Object.keys(selectedProducts).map(productId => {
      // Check if the product ID in selectedProducts exists in productFields
      if (!productFields[selectedProducts[productId]]) {
        return null; // Skip rendering if product ID is not found
      }
      const productName = productFields[selectedProducts[productId]].Name;
      return (
        <TableHead key={productId} colSpan={2} className="text-center border-x-2">{productName}</TableHead>
      );
    })}
    <TableHead colSpan={1}></TableHead>
    <TableHead colSpan={1}></TableHead>
  </TableRow>
    <TableRow>
      {orderedKeys.map(key => (
        <TableHead key={key}>{key}</TableHead>
      ))}
    </TableRow>
  </TableHeader>
  <TableBody>
  {results.map((result, index) => (
    <TableRow key={index}>
      <TableCell>{result.year}</TableCell>
      {Object.keys(selectedProducts).map(productId => {
        // Check if product data exists in this result
        const productData = result.products[productId];
        if (!productData) {
          return null; // Skip rendering the cells if no data for this product
        }

        return <React.Fragment key={productId}>
          <TableCell>{productData.return ? `${productData.return}%` : ''}</TableCell>
          <TableCell>{productData.endingBalance ? `$${new Intl.NumberFormat('en-US').format(productData.endingBalance)}` : ''}</TableCell>
        </React.Fragment>;
      })}
      <TableCell>{result.weightedAverageReturn ? `${result.weightedAverageReturn}%` : ''}</TableCell>
      <TableCell>{result.totalEndingBalance ? `$${new Intl.NumberFormat('en-US').format(result.totalEndingBalance)}` : ''}</TableCell>
    </TableRow>
  ))}
</TableBody>
      </Table>
      )}

      {/* Total Return Summary */}
      {results.length > 0 && (
        <div>
        <h1 className='text-2xl font-bold font-serif text-center '>Your Results</h1>
          <p className='text-center'>
          <span className='font-bold'>Total Return Percentage:</span> {totalReturn}%<br />
          {/* <span className='font-bold'>Annualized Return:</span> {twr}%<br /> */}
          <span className='font-bold'>Total Profit:</span> ${new Intl.NumberFormat('en-US').format(totalProfit)}
        </p>
      </div>
      )}
    </div>
    </div>
  );
}

export default RoboCalculator;

import React, { useEffect, useRef } from 'react';

const Respondio = () => {
  const widgetRef = useRef(null);

  useEffect(() => {
    if (!document.getElementById('respondio__widget') && widgetRef.current) {
      const script = document.createElement('script');
      script.id = 'respondio__widget';
      script.src = 'https://cdn.respond.io/webchat/widget/widget.js?cId=ec44c00faafc581f128ad5cfa87071a1fe0589046ad4c53c8bd0e2b1559426df';
      script.async = true;

      widgetRef.current.appendChild(script);
    }
  }, [widgetRef]);

  return <div ref={widgetRef} />;
};

export default Respondio;
import React, { useEffect, useState, useRef } from 'react';
import ConvertKitForm from 'convertkit-react'
import Image from '../components/Image'
import MBG from '../components/MBG'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion"
import { Helmet } from 'react-helmet';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../components/ui/carousel"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card"
import StickyBar from '../components/ui/sticky-banner'
import Countdown from '../components/Countdown'
import BasicForm from '../components/BasicForm'

const InvestingCourse = () => {
  return (
    <div>
      <Helmet>
        <title>Investing Course for Non Finance Bros</title>
        <meta name="description" content='A hands on one-month program that will help you create your own personalised investment strategy without the yawns'/>
        <meta charSet="utf-8" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.png" />
        <meta property="og:title" content="A hands on one-month program that will help you create your own personalised investment strategy without the yawns"/>
        <meta property="og:description" content="A hands on one-month program that will help you create your own personalised investment strategy without the yawns"/>
        <meta property="og:image" href="%PUBLIC_URL%/favicon.png" />
      </Helmet>

      {/* <StickyBar title='Course Closes in' 
      paragraph={<Countdown targetDateString={'20-02-2024-23-59'} />}
      ctaLink='https://cart.sav.finance/start-investing/?coupon=2024'
      ctaText='Sign Up Now'
      bgColor='bg-red-50'
      /> */}


      
    <body className="bg-investing-course">
      <section className="py-6 dark:bg-gray-900 space-y-6 pt-8">
        <div className="flex flex-col md:flex-row gap-8 px-4 mx-auto max-w-screen-lg items-center">
          <div className="flex flex-col pb-8 gap-y-6">
            <h1 className="text- md:text-start font-extrabold tracking-tight leading-none text-gray-900 text-4xl md:text-6xl dark:text-white text-balance">Design a personalised investment strategy & start building your wealth</h1>
            <Image src='investing-course-cover1x1.jpg' className='block md:hidden'/>
            <h2 className='text-start text-2xl text-pretty'>*The* hands on investing course <span className='bg-purple-50'>made for beginners</span>. Explore your options, create your unique lifelong investment plan & get your passive income flowing.</h2>
            <p className=" text-gray-500 dark:text-gray-400 text-lg">So you wanna start or improve on investing but: a) you don’t know your other options just that the stock market exists b) you don’t have the TIME to research what’s out there c) when you do, the numbers and finance bro jargon scare you off 🥵 d) WHO do you even ask???<br/><br/>

We’re here to let you know that investing isn’t just for the finance bros. You don’t need to remain clueless about one of the best tools for building wealth. In fact, creating your own strategy that works for you has never been easier.  This is more than just learning investment basics — our goal is to help you learn those tricky financial concepts alongside the Sav community so that you’re not figuring this out alone.</p>
            
          </div>
          <div className='hidden md:block rounded-2xl basis-1/3 flex-none'>
          <Image src='investing-course-cover.jpg'/>
          </div>
        </div>
      </section>
      
      <section id='who-for' className='max-w-screen-lg mx-auto py-8 my-8'>
      <div className="flex flex-col gap-y-2">
          <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance pb-8">Who is this course for?</h2>
        </div>
        <div className="block md:hidden space-x-4 p-4 ">
              <div className="flex flex-row overflow-auto gap-8 rounded-md -ml-2 *:bg-white">
              <div className='flex flex-col flex-none aspect-4/5 w-48 p-4 border rounded-xl '>
                  <h3 className='text-md md:text-2xl font-bold flex-grow'>I’m new to investing, but I want to learn</h3>
                  <p className='pt-4'>I’m intimidated by numbers and big words, but I’d like something that helps break down complex financial jargons for me to better understand it. I’m no longer shying away from future financial self-care.</p>
                </div>
                <div className='flex flex-col flex-none aspect-4/5 w-48 p-4 border rounded-xl '>
                  <h3 className='text-md md:text-2xl font-bold flex-grow'>I want to build passive income without a second job</h3>
                  <p className='pt-4'>I want to be able to build wealth that doesn’t require me to take up more work. I want to start working smarter, not just harder in growing my money to achieve my goals and retire comfortably. </p>
                </div>
                <div className='flex flex-col flex-none aspect-4/5 w-48 p-4 border rounded-xl '>
                  <h3 className='text-md md:text-2xl font-bold flex-grow'>I want to know all my options out there to make informed decisions</h3>
                  <p className='pt-4'>I’ve only ever dabbled in robo-advisors, but I want to be able to take more ownership of my money. I want to know what other options are out there and get the best returns for myself. </p>
                </div>
                
                <div className='flex flex-col flex-none aspect-4/5 w-48 p-4 border rounded-xl '>
                  <h3 className='text-md md:text-2xl font-bold flex-grow'>I suck at mathing but I wanna learn how to invest!</h3>
                  <p className='pt-4'>Is it not possible to learn how to invest without all the jargon?!</p>
                </div>
                <div className='flex flex-col flex-none aspect-4/5 w-48 p-4 border rounded-xl'>
                <h3 className='text-md md:text-2xl font-bold flex-grow'>I’m a beginner and want something that is not so intimidating.</h3>
                <p className='pt-4'>I need easy-to-understand modules and be with people on similar levels.</p>
                </div>
                <div className='flex flex-col flex-none aspect-4/5 w-48 p-4 border rounded-xl'>
                <h3 className='text-md md:text-2xl font-bold flex-grow'>I am a woman and feel excluded from all these investing courses</h3>
                  <p className='pt-4'>I want to build my financial confidence in a supportive learning environment and learn things I am interested in.</p>
                </div>
              </div>
          </div>
          <div className='hidden md:block'>
          <div className="flex flex-row overflow-auto gap-8 rounded-md -ml-2 *:bg-white">
          <div className='flex flex-col flex-none  basis-1/3 p-4 border rounded-xl'>
                  <h3 className='text-md md:text-2xl font-bold flex-grow'>I’m new to investing, but I want to learn</h3>
                  <p className='pt-4'>I’m intimidated by numbers and big words, but I’d like something that helps break down complex financial jargons for me to better understand it. I’m no longer shying away from future financial self-care.</p>
                </div>
                <div className='flex flex-col flex-none  basis-1/3 p-4 border rounded-xl'>
                  <h3 className='text-md md:text-2xl font-bold flex-grow'>I want to build passive income without a second job</h3>
                  <p className='pt-4'>I want to be able to build wealth that doesn’t require me to take up more work. I want to start working smarter, not just harder in growing my money to achieve my goals and retire comfortably.</p>
                </div>
                <div className='flex flex-col flex-none  basis-1/3 p-4 border rounded-xl'>
                  <h3 className='text-md md:text-2xl font-bold flex-grow'>I want to know all my options out there to make informed decisions</h3>
                  <p className='pt-4'>I’ve only ever dabbled in robo-advisors, but I want to be able to take more ownership of my money. I want to know what other options are out there and get the best returns for myself.</p>
                </div>
                <div className='flex flex-col flex-none  basis-1/3 p-4 border rounded-xl'>
                  <h3 className='text-md md:text-2xl font-bold flex-grow'>I suck at mathing but I wanna learn how to invest!</h3>
                  <p className='pt-4'>Is it not possible to learn how to invest without all the jargon?!</p>
                </div>
                <div className='flex flex-col flex-none basis-1/3 p-4 border rounded-xl'>
                <h3 className='text-md md:text-2xl font-bold flex-grow'>I’m a beginner and want something that is not so intimidating.</h3>
                <p className='pt-4'>I need easy-to-understand modules and be with people on similar levels.</p>
                </div>
                <div className='flex flex-col flex-none basis-1/3 p-4 border rounded-xl'>
                <h3 className='text-md md:text-2xl font-bold flex-grow'>I am a woman and feel excluded from all these investing courses</h3>
                  <p className='pt-4'>I want to build my financial confidence in a supportive learning environment and learn things I am interested in.</p>
                </div>
              </div>
          </div>
      </section>

      <section id='curriculum' className='max-w-screen-lg mx-auto my-8 px-4'>
        
        <div className="flex flex-col gap-y-2">
          <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance pb-8">The Curriculum</h2>
          <p className='text-start text-gray-500 dark:text-gray-400'>The Non Finance Bro's Investing Course is a 4 week program with hands on support from the sav team. In just 15-25mins each module, you'll join a group of other students in building a foundation in investing, learning about dozen of options, and then finally designing a personalised investment strategy just for you.</p>
          <p className="mb-6 text-gray-500 dark:text-gray-400">The program is designed to be easy to follow, appealing on the eyes, and most importantly, instantly transformational. As you progress through the weeks, you’ll quickly notice tangible changes your ability to understand investing.</p>
          <p className="mb-6 text-gray-500 dark:text-gray-400">By the end of the program, you'll have a personalised investment portfolio that you would have tested multiple times in our mock future.</p>
        </div>
        <div className='w-full mx-auto pt-8'>
        <Accordion type="single" collapsible className='*:bg-white *:px-4'>
          <AccordionItem value="item-1">
            <AccordionTrigger className='hover:cursor-pointer hover:bg-gray-100 '>
              <div className='flex flex-row items-center gap-2 '>
                <div className='flex flex-col pl-4 pr-2 md:pr-6 '>
                  <p className='text-sm uppercase'>module</p>
                  <p className='text-xl'>1</p>
                </div>
                <h2 className='text-xl md:text-2xl font-bold text-start'>Your Investing Journey Begins!</h2>
              </div>
              </AccordionTrigger>
            <AccordionContent>
              <div className="mb-6 text-gray-500 dark:text-gray-400 px-2 text-lg mx-auto">
              This course was created to be experienced with a group of people. So this module is to get to know each other with orientation and community introductions.
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger className='hover:cursor-pointer hover:bg-gray-100 '>
              <div className='flex flex-row items-center gap-2 '>
                <div className='flex flex-col pl-4 pr-2 md:pr-6 '>
                  <p className='text-sm uppercase'>module</p>
                  <p className='text-xl'>2</p>
                </div>
                <h2 className='text-xl md:text-2xl font-bold text-start'>Overcome Your Fear of Starting</h2>
              </div>
              </AccordionTrigger>
            <AccordionContent>
            <div className="mb-6 text-gray-500 dark:text-gray-400 px-2 text-lg mx-auto">
              You may have a lot of reservations of whether or not investing is for you, or what investing is. We'll answer them all in this module so by the end of this, you'll have a level of confidence that you can be successful investing.
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger className='hover:cursor-pointer hover:bg-gray-100 '>
              <div className='flex flex-row items-center gap-2 '>
                <div className='flex flex-col pl-4 pr-2 md:pr-6 '>
                  <p className='text-sm uppercase'>module</p>
                  <p className='text-xl'>3</p>
                </div>
                <h2 className='text-xl md:text-2xl font-bold text-start'>Understanding Investing Basics</h2>
              </div>
              </AccordionTrigger>
            <AccordionContent>
            <div className="mb-6 text-gray-500 dark:text-gray-400 px-2 text-lg mx-auto">
              Now that you have the confidence that you can do this, we'll start talking about what investing is, how it plays a role in our life, and how you can have a healthy relationship with it.
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4">
            <AccordionTrigger className='hover:cursor-pointer hover:bg-gray-100 '>
              <div className='flex flex-row items-center gap-2 '>
                <div className='flex flex-col pl-4 pr-2 md:pr-6 '>
                  <p className='text-sm uppercase'>module</p>
                  <p className='text-xl'>4</p>
                </div>
                <h2 className='text-xl md:text-2xl font-bold text-start'>Before the Investing Begins</h2>
              </div>
              </AccordionTrigger>
            <AccordionContent>
            <div className="mb-6 text-gray-500 dark:text-gray-400 px-2 text-lg mx-auto">
              You're now mentally ready to invest. You have a better relationship with the idea of investing. But are your financial circumstances ready for it? In this module, we'll focus on cleaning up your finances and setting some clear financial goals. Now our investing goal is to make those financial goals possible.
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-5">
            <AccordionTrigger className='hover:cursor-pointer hover:bg-gray-100 '>
              <div className='flex flex-row items-center gap-2 '>
                <div className='flex flex-col pl-4 pr-2 md:pr-6 '>
                  <p className='text-sm uppercase'>module</p>
                  <p className='text-xl'>5</p>
                </div>
                <h2 className='text-xl md:text-2xl font-bold text-start'>What Type of Investor Are You?</h2>
              </div>
              </AccordionTrigger>
            <AccordionContent>
            <div className="mb-6 text-gray-500 dark:text-gray-400 px-2 text-lg mx-auto">
              You're probably not a finance bro, but what type of investor are you? In this module, you'll find your answer and also find out what types of investments are a natural fit given your type.
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-6">
            <AccordionTrigger className='hover:cursor-pointer hover:bg-gray-100 '>
              <div className='flex flex-row items-center gap-2 '>
                <div className='flex flex-col pl-4 pr-2 md:pr-6 '>
                  <p className='text-sm uppercase'>module</p>
                  <p className='text-xl'>6</p>
                </div>
                <h2 className='text-xl md:text-2xl font-bold text-start'>Your Investment Options</h2>
              </div>
              </AccordionTrigger>
            <AccordionContent>
            <div className="mb-6 text-gray-500 dark:text-gray-400 px-2 text-lg mx-auto">
                You now know what type of investor you are. You know in general, what type of investments you should focus on. Now it's time to actually find out what the options look like. In this modeule, we'll be introducing you to all the investment options, their pros & cons, as well as how they could potentially fit in with your investor type.
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-7">
            <AccordionTrigger className='hover:cursor-pointer hover:bg-gray-100 '>
              <div className='flex flex-row items-center gap-2 '>
                <div className='flex flex-col pl-4 pr-2 md:pr-6 '>
                  <p className='text-sm uppercase'>module</p>
                  <p className='text-xl'>7</p>
                </div>
                <h2 className='text-xl md:text-2xl font-bold text-start'>Design Your Investing Strategy</h2>
              </div>
              </AccordionTrigger>
            <AccordionContent>
            <div className="mb-6 text-gray-500 dark:text-gray-400 px-2 text-lg mx-auto">
                By now, you know what type of investor you are. You know the options you have and which ones make sense for what parts of your life. Now it's time to put it all together and design your own personalised investment strategy! But that's not all. You'll also test that investment strategy for yourself in a safe environment & experience the ups and downs that we've covered in earlier modules.
                And if the experiences become too much, you can always redesign it until you find one that works!
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-8">
            <AccordionTrigger className='hover:cursor-pointer hover:bg-gray-100 '>
              <div className='flex flex-row items-center gap-2 '>
                <div className='flex flex-col pl-4 pr-2 md:pr-6 '>
                  <p className='text-sm uppercase'>module</p>
                  <p className='text-xl'>8</p>
                </div>
                <h2 className='text-xl md:text-2xl font-bold text-start'>Beyond the Milky Way</h2>
              </div>
              </AccordionTrigger>
            <AccordionContent>
            <div className="mb-6 text-gray-500 dark:text-gray-400 px-2 text-lg mx-auto">
                You've made it to the end. You've personally designed your own investing strategy. Now it's time to actually take the steps to create it in the real world and get back to living life. Because you've already experienced what happens when you invest.
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        </div>
        <div className='flex flex-col mx-auto py-8 min-w-screen-xl overflow-x-auto md:items-center'>
        <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance pb-8">The Schedule</h2>
        <p>The dates highlighted are when new sessions will be made available.</p>
        <div>
          <div className='flex flex-row *:p-2  *:w-32 *:border-2 *:flex-none'>
            <div className='font-bold text-center'>Sunday</div>
            <div className='font-bold text-center'>Monday</div>
            <div className='font-bold text-center'>Tuesday</div>
            <div className='font-bold text-center'>Wednesday</div>
            <div className='font-bold text-center'>Thursday</div>
            <div className='font-bold text-center'>Friday</div>
            <div className='font-bold text-center'>Saturday</div>
          </div>
          <div className='flex flex-row *:aspect-square *:p-2  *:w-32 *:border-2 *:flex-none'>
          <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
              <div className='flex flex-row items-center justify-between'><p className='font-bold'>18 Feb</p><div className='text-xs bg-purple-500 rounded-2xl py-2 px-4 font-bold text-white'>Live</div></div>
              <p>Your investing journey begins! + Overcome Your Fear of Starting</p>
            </div>
          </div>
          <div className='font-bold'>19</div>
          <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
            <p className='font-bold'>20</p><p>Understanding investing basics part 1</p>
            </div>
          </div>
          <div className='font-bold'>21</div>
          <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
            <p className='font-bold'>22</p><p>Understanding investing basics part 2</p>
            </div>
          </div>
          <div className='font-bold'>23</div>
          <div className='font-bold'>24</div>
        </div>
        <div className='flex flex-row *:aspect-square *:p-2  *:w-32 *:border-2 *:flex-none'>
          <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
            <p className='font-bold'>25</p><p>Before the investing begins part 1</p>
            </div>
          </div>
          <div className='font-bold'>26</div>
          <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
            <p className='font-bold'>27</p><p>Before the investing begins part 2</p>
            </div>
          </div>
          <div className='font-bold'>28</div>
          <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
            <p className='font-bold'>29</p><p>What type of investor are you?</p>
            </div>
          </div>
          <div className='font-bold'>1 March</div>
          <div className='font-bold'>2</div>
        </div>
        <div className='flex flex-row *:aspect-square *:p-2  *:w-32 *:border-2 *:flex-none'>
        <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
            <p className='font-bold'>3</p><p>Your investment options part 1</p>
            </div>
          </div>
          <div className='font-bold'>4</div>
          <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
            <p className='font-bold'>5</p><p>Your investment options part 2</p>
            </div>
          </div>
          <div className='font-bold'>6</div>
          <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
            <div className='flex flex-row items-center justify-between'><p className='font-bold'>7</p><div className='text-xs bg-purple-500 rounded-2xl py-2 px-4 font-bold text-white'>Live</div></div>
              <p>Live Q&A <br/>Will be recorded</p>
            </div>
          </div>
          <div className='font-bold'>8</div>
          <div className='font-bold'>9</div>
        </div>
        <div className='flex flex-row *:aspect-square *:p-2  *:w-32 *:border-2 *:flex-none'>
        <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
            <p className='font-bold'>10</p><p>Design your invesstment strategy part 1</p>
            </div>
          </div>
          <div className='font-bold'>11</div>
          <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
            <p className='font-bold'>12</p><p>Design your invesstment strategy part 2</p>
            </div>
          </div>
          <div className='font-bold'>13</div>
          <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
            <p className='font-bold'>14</p><p>Design your invesstment strategy part 3</p>
            </div>
          </div>
          <div className='font-bold'>15</div>
          <div className='font-bold'>16</div>
        </div>
        <div className='flex flex-row *:aspect-square *:p-2  *:w-32 *:border-2 *:flex-none'>
        <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
            <div className='flex flex-row items-center justify-between'><p className='font-bold'>17</p><div className='text-xs bg-purple-500 rounded-2xl py-2 px-4 font-bold text-white'>Live</div></div>
              <p>Live Q&A <br/>Will be recorded</p>
            </div>
          </div>
          <div className='font-bold'>18</div>
          <div className='bg-yellow-50'>
            <div className='flex flex-col space-2'>
            <div className='flex flex-row items-center justify-between'><p className='font-bold'>19</p><div className='text-xs bg-purple-500 rounded-2xl py-2 px-4 font-bold text-white'>Live</div></div>
              <p>Beyond the milky way</p>
            </div>
          </div>
          </div>
        </div>
        </div>
      </section>

      <section id='bonuses' className='max-w-screen-lg mx-auto py-8 my-8'>
        <div className="flex flex-col gap-y-2">
          <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance pb-8">Included in the course ↘</h2>
        </div>
        <div className='flex flex-col gap-4 md:gap-8'>
        <div className="flex flex-col md:w-full md:flex-row gap-2 md:gap-8 bg-white py-8 mx-4 md:mx-auto max-w-screen-lg xl:gap-16 sm:py-8 px-4 lg:px-6 rounded-3xl border-2 justify-center items-center">
            <div className="order-1 max-w-xs">
              <Image src='investing-course-lingo-translator1x1.jpg'/>
            </div>
            <div className="order-2 py-2">
              <h2 className="text-start font-extrabold tracking-tight leading-none text-gray-900 text-xl dark:text-white text-balance pb-4">
                WTF!?! Investment Lingo Translator
              </h2>
              <p className="text-gray-500 dark:text-gray-400 text-lg">Finance bros like to make it all complicated and stuff. But we've got you.<br/>This investment ling translator will give you a list of all the terms you may run across as well as examples to show you what it really means. To the point where your friends will approach you for explanations.</p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-2 md:gap-8 bg-white py-8 mx-4 md:mx-auto max-w-screen-lg xl:gap-16 sm:py-8 px-4 lg:px-6 rounded-3xl border-2 justify-center items-center">
            <div className="order-1 md:order-last max-w-xs">
            <Image src='investing-course-handbook1x1.jpg'/>
            </div>
            <div className="order-2 py-2">
              <h2 className="text-areR font-extrabold tracking-tight leading-none text-gray-900 text-xl dark:text-white text-balance pb-4">
              The Ultimate Investment Vehicle Handbook
              </h2>
              <p className="text-gray-500 dark:text-gray-400 text-lg">See all the different types of investment options you have. From things like robo advisors and stocks to maybe even Chanel bags?</p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-2 md:gap-8 bg-white py-8 mx-4 md:mx-auto max-w-screen-lg xl:gap-16 sm:py-8  px-4 lg:px-6 rounded-3xl border-2 justify-center items-center">
            <div className="order-1 max-w-xs">
            <Image src='investing-course-crystal-ball1x1.jpg'/>
            </div>
            <div className="order-2 py-2">
              <h2 className="text-start font-extrabold tracking-tight leading-none text-gray-900 text-xl dark:text-white text-balance pb-4">
                Your Personal Investment Crystal Ball
              </h2>
              <p className="text-gray-500 dark:text-gray-400 text-lg">Ever wonder if you're on the right track with your investments? What if there's a way to see what a potential outcome could be? The investment crystal ball was made just for that. Create a portfolio and test how it may do throughout your life.</p>
              <p className="text-gray-500 dark:text-gray-400 pt-2 text-lg">Once you're comfortable with it, you may have yourself a personalised investment strategy.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="instructors" className='max-w-screen-lg mx-auto my-8 px-4'>
        <div className="flex flex-col gap-y-2">
          <h2 className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance pb-6">Meet your instructors</h2>
          <div className="flex flex-col md:flex-row gap-4 md:gap-6 mx-4">
            <div className="flex flex-col basis-1/2">
              <Image src='joey.jpeg' className='aspect-square'/>
              <h3 className="text-center py-4">Joey</h3>
              <p className="mb-6 text-gray-500 dark:text-gray-400">Just four years ago, Joey (sav co-founder) was a newbie freelancer constantly getting stressed out about where her money was going (guilty pleasure: skincare 😵‍💫). Eventually she realised that <b className="text-msc-orange">YOLO-ing her finances wasn’t going to cut it for her future.</b></p>
              <p className="mb-6 text-gray-500 dark:text-gray-400">Today, she stresses wayyyy less about money. She comfortably rents a studio in Joo Chiat, and has enough spaciousness in her budget to travel multiple times a year. </p>
            </div>
            <div className="flex flex-col basis-1/2">
              <Image src="chungsoon1x1.jpg" className='aspect-square'/>
              <h3 className="text-center py-4">CS</h3>
              <p className="mb-6 text-gray-500 dark:text-gray-400">CS made a lot of mistakes with money and investing while in uni. But the worst part was how he ended up treating those he loves.</p>
                <p className="mb-6 text-gray-500 dark:text-gray-400">After losing an ex, he started to learn to have a healthier relationship with money (and with himself). That led him to learn how to invest the right way and a few years later, was able to hit $100k before his 30s.</p>

              <p className="mb-6 text-gray-500 dark:text-gray-400">Today, he spends most of his time building out sav so that more people won't go through what he did. </p>
            </div>
          </div>
         
        </div>
      </section>

      <section id="pay" className=' mx-auto my-8 bg-white py-8'>
        <div className="flex flex-col mx-4 md:mx-auto gap-y-4 items-center">
          <h2 id="start" className="text-center font-extrabold tracking-tight leading-none text-gray-900 text-6xl dark:text-white text-balance">Ready to get started?</h2>
          {/* <p className='text-center'>Course Closes in:</p>
          <h3 className='text-center text-6xl'><Countdown targetDateString={'20-02-2024-23-59'} /></h3> */}
            <h3 className='text-center text-4xl'>$199</h3>
            <p className='text-center'>or $79/mo with our payment plan</p>
            <BasicForm CTA='Join Waitlist' fetch_url='/my/api/course-waitlist' success="🎉 You're on the waitlist!" />
          {/* <a className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 rounded text-center mx-auto px-8" href="https://cart.sav.finance/start-investing/?coupon=2024">Save Your Spot Risk Free</a> */}
          {/* <p className='text-center'>Enrollment ends <span className='bg-yellow-50'>Sunday, 18 Febraury.</span></p> */}
        </div>
        <div className='py-8 mx-4 rounded-2xl'><MBG days={7}/></div>
      </section>

      

      <section id='faq' className=' mx-auto flex flex-col py-8'>
      <h1 className="text-center md:text-start font-extrabold tracking-tight leading-none text-gray-900 text-4xl dark:text-white text-balance mx-auto">FAQ</h1>
      <Accordion type="single" collapsible className='max-w-2xl mx-auto px-4 w-full'>
        <AccordionItem value="item-1">
        <AccordionTrigger className='text-start text-pretty text-xl md:text-2xl font-bold'>Why is this course better than other investing courses?</AccordionTrigger>
          <AccordionContent>
            <div>
            <p className="mb-6 text-gray-500 text-lg dark:text-gray-400">At sav we’re all about edutainment (that’s if education & entertainment had a baby), which is why we wanted to teach you investing in the most fun way possible. Our goal is to make you have confidence that you're on the right path.</p>

              <p className="mb-6 text-gray-500 text-lg dark:text-gray-400">We take extra time to make this course as edu-taining as possible by (1) making all the finance-y things as easy to understand as possible and (2) exercises designed to be actionable so you’ll be able to apply your learnings away—because learning is way more fun when you can actually do something with it.</p>

              <p className="mb-6 text-gray-500 text-lg dark:text-gray-400">Not to mention we have a private space on sav club where you’ll have the chance to chat and network with others going through the same journey!</p>

              <p className="mb-6 text-gray-500 text-lg dark:text-gray-400">Of course if you're looking for more of a finance bro type of course, we definitely are not for you.</p>
            </div>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-2">
        <AccordionTrigger className='text-start text-pretty text-xl md:text-2xl font-bold'>What payment options do you have?</AccordionTrigger>
          <AccordionContent>
          <p className="mb-6 text-gray-500 text-lg dark:text-gray-400">We understand that forking out $199 may be a bit too sudden for some. That said, we offer a 2 month and 3 month payment plan. We also offer a 10% discount if you and a friend decide to go through it together.</p>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-3">
        <AccordionTrigger className='text-start text-pretty text-xl md:text-2xl font-bold'>What time are the sessions?</AccordionTrigger>
          <AccordionContent>
          <p className="mb-6 text-gray-500 text-lg dark:text-gray-400">Although this is a self paced program, we have many activities happening in sav club. That said, we will release the sessions and activities at 8pm. Our lives will also be at 8pm.</p>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-3">
        <AccordionTrigger className='text-start text-pretty text-xl md:text-2xl font-bold'>Are the Live Sessions 1-on-1 or a group call?</AccordionTrigger>
          <AccordionContent>
          <p className="mb-6 text-gray-500 text-lg dark:text-gray-400">The Live Office Hour is a live group video call (recorded if you can’t make it). If we're unable to answer the question in the live call, we will answer them on sav club.</p>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-4">
          <AccordionTrigger className='text-start text-pretty text-xl md:text-2xl font-bold'>How can I convince my employer to reimburse my education fees?
</AccordionTrigger>
          <AccordionContent>
          <p className="mb-6 text-gray-500 text-lg dark:text-gray-400">The key thing to remember when you ask is that the company is also getting a deal and relative to their other expenses, this course is a drop in the bucket. That said, <a className="font-bold text-green-600" href="https://docs.google.com/document/d/1j_IV9cxQAMZJXU7J9jUvGRRUg4Vp9d0KiskFJIz9C4Q/view" target="_blank" rel="noreferrer">here is a template letter</a> that you can use to send an email to your manager.</p>
          <p className="mb-6 text-gray-500 text-lg dark:text-gray-400">Anothing thing we can explore is offering the course to your company as a private purchase. If this is something you'd like to help with, let us know!</p>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-5">
          <AccordionTrigger className='text-start text-pretty text-xl md:text-2xl font-bold'>I really can't afford it even with all the options you provided. Is there a scholarship?
</AccordionTrigger>
          <AccordionContent>
          <p className="mb-6 text-gray-500 text-lg dark:text-gray-400">Please DM us and we'll see what we can arrange for you.</p>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      </section>


    </body>
    </div>
  );
}

export default InvestingCourse;
import React, { createContext, useEffect, useState  } from 'react';

export const GlobalContext = React.createContext(null);
export const GlobalProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Only attempt to fetch user data if not already loaded
    if (!user) {
      fetch('/my/api/initiate')
        .then(response => {
          if (response.status === 401) {
            // Redirect to the login page if unauthorized
            window.location.href = '/login';
            return Promise.reject('Unauthorized');
          }
          return response.json();
        })
        .then(data => {
          setUser(data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    }
  }, [user]);
  

  return (
    <GlobalContext.Provider value={{ user, loading }}>
      {children}
    </GlobalContext.Provider>
  );
};


import React, { useEffect, useState, useRef } from 'react';
import Image from '../components/Image'
import Retirement from '../components/Retirement'
import RoboGuide from './RoboGuide'

const RetirementLP = () => {
  const [showRoboGuide, setShowRoboGuide] = useState(false);
  const handleButtonClick = () => {
    setShowRoboGuide(!showRoboGuide);
  };
  if (showRoboGuide) {
    return (
      <div>
        <button onClick={handleButtonClick}>Back to Reitrement Calculator</button>
        <RoboGuide />
      </div>
    );
  } else {
    return (
      <body className="bg-msc-bg">
        <section className='flex flex-col mx-4 md:mx-auto'>
          <Retirement />

          <div className='flex flex-col md:mx-auto max-w-2xl py-8 gap-4'>
            <h1 className='font-serif text-4xl md:text-6xl'>So now what?</h1>
            <p className='text-start'>You've mentioned that you expect a certain annual rate of return, but how do you get it?</p>
            <p>Might we suggest <b>robo+</b>. It has consolidated all robo information so you can compare and create your own personal robo portfolio and see what the expected annual rate of return would be.</p>
            <button onClick={handleButtonClick}>Learn More About Robo+</button>
          </div>
        </section>
      </body>
    );
  }
}

export default RetirementLP;
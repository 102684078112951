import React, { useEffect, useState, useContext } from 'react';
import Image from './Image'


const GeneralNav = () => {
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);

  // Function to toggle dropdown visibility
  const toggleMobileDropdown = () => {
      setIsMobileDropdownOpen(!isMobileDropdownOpen);
  };

  // Initialize dropdown state on component mount
  useEffect(() => {
      setIsMobileDropdownOpen(false); // Set initial state of dropdown
  }, []);

    return (
          <nav className="bg-gray-100 border-gray-200 px-4 py-2 dark:bg-gray-800 dark:border-gray-700 relative left-0 right-0 top-0 z-50 ">
  <div id="desktop" className="mx-auto px-4 sm:px-8 lg:px-12 hidden md:block max-w-screen-xl">
    <div className="flex justify-between items-center">
      <div className="md:flex items-center md:gap-12">
          <a href="/"><Image src='sav-logo-tp.png' className='w-16' alt='sav-logo' imgClass='w-16'/></a>
      </div>

      <div className="md:flex md:items-center md:gap-12">
        <div aria-label="Global" className="hidden md:block">
          <ul className="flex items-center gap-6 text-sm">
              {/* <li>
                  <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="/my/resume-feedback">Resume Feedback</a>
              </li>
              <li>
                  <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="/my/get-cover-letter">Cover Letter</a>
              </li> */}
              {/* <li>
                <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="/my/workshop/2">Workshop</a>
              </li> */}
              <li>
                <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="/products/collection">Products</a>
              </li>
              <li>
                <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="/free">Free Resources</a>
              </li>
              <li>
              <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="https://club.sav.finance">sav Club</a>
              </li>
              <li>
                <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="/work-with-us">Work with us</a>
              </li>
          </ul>
        </div>
      </div>
      <div className="flex flex-row items-center gap-4">
      <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="/member/login">Login</a>
      <a className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded" href="/member/register">Register</a>
      </div>
    </div>

  </div>

  <div id="mobile" className="relative block md:hidden w-full">
    <div className="flex justify-between items-center">
      <div className="md:flex md:gap-12">
        <a href="/my/"><Image src='sav-logo-tp.png' className='w-16' alt='sav-logo' imgClass='w-16'/></a>
      </div>
      <div>
      <button onClick={toggleMobileDropdown} className="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16"/>
        </svg>
      </button>
      <div className={`absolute top-full left-0 w-full my-2 pb-4 px-4 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden z-10 ${
        isMobileDropdownOpen ? 'block' : 'hidden'
      }`} 
      id="mobileDropdown"
    >
      <ul className="w-full text-md py-2">
        {/* <li><a className="block hover:bg-indigo-100 px-4 py-2" href="/my/resume-feedback">Resume Feedback</a></li>
        <li><a className="block hover:bg-indigo-100 px-4 py-2" href="/my/get-cover-letter">Cover Letter</a></li> */}
        {/* <li><a className="block hover:bg-indigo-100 px-4 py-2" href="/my/workshop/2">Workshop</a></li> */}
        <li><a className="block hover:bg-indigo-100 px-4 py-2" href="/products">Products</a></li>
        <li><a className="block hover:bg-indigo-100 px-4 py-2" href="/free">Free Resources</a></li>
        <li><a className="block hover:bg-indigo-100 px-4 py-2" href="https://club.sav.finance">sav Club</a></li>
        <li><a className="block hover:bg-indigo-100 px-4 py-2" href="/work-with-us">Work with us</a></li>
        <li><a className="block hover:bg-indigo-100 px-4 py-2" href="/member/login">Login</a></li>
      </ul>
      <a className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded" href="/member/register">Register</a>
      </div>
    </div>
  </div>
  </div>
</nav>
    );
}

const handleLogout = () => {
  fetch('/my/api/logout', { method: 'GET' }) // Adjust the endpoint as necessary
    .then(() => {
      // Redirect to login page or handle UI changes
      window.location.href = '/'; // Update with your login route
    })
    .catch(error => console.error('Logout failed:', error));
};

export default GeneralNav;

import React, { useEffect, useState, useContext } from 'react';
import { withGlobalState } from './HOC';
import Banner from '../components/ui/banner'
import Image from './Image'

const Nav = ({ user, loading, error }) => {
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  // Function to toggle dropdown visibility
  const toggleMobileDropdown = () => {
      setIsMobileDropdownOpen(!isMobileDropdownOpen);
  };

  // Initialize dropdown state on component mount
  useEffect(() => {
      setIsMobileDropdownOpen(false); // Set initial state of dropdown
  }, []);


  const renderUserCredits = () => {
    if (!loading && user && user.fields && user.fields['Credits']) {
      return (
        <p className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 capitalize">
          you have {user.credits} credits
        </p>
      );
    }
    <button onClick={handleLogout} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
    Logout
  </button>
    return null; // Or return a placeholder/loading indicator if preferred
  };

    return (
          <nav className="bg-gray-100 border-gray-200 px-4 py-2 dark:bg-gray-800 dark:border-gray-700 relative left-0 right-0 top-0 z-50 ">
  <div id="desktop" className="mx-auto px-4 sm:px-8 lg:px-12 hidden md:block max-w-screen-xl">
    <div className="flex justify-between items-center">
      <div className="md:flex items-center md:gap-12">
          <a href="/my/"><Image src='sav-logo-tp.png' className='w-16' alt='sav-logo' imgClass='w-16'/></a>
      </div>

      <a className='flex flex-col hover:*:text-lg hover:cursor-pointer bg-purple-50 rounded-xl p-2' href='/products/investing-course'>
            <p className='font'>Design a personalised investment strategy</p>
            <p className='text-underline font-bold text-green-600'>Start risk free ↗</p>
          </a>

      <div className="md:flex md:items-center md:gap-12">
        <div aria-label="Global" className="hidden md:block">
          <ul className="flex items-center gap-6 text-sm">
              {/* <li>
                  <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="/my/resume-feedback">Resume Feedback</a>
              </li>
              <li>
                  <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="/my/get-cover-letter">Cover Letter</a>
              </li> */}
              <li>
                <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="/my/career">Tools</a>
              </li>
              <li>
                <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="/my/resources">Resources</a>
              </li>
              <li>
                <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="/my/profile">My profile</a>
              </li>
              <li>
              <a className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" href="https://club.sav.finance">sav Club</a>
              </li>
          </ul>
        </div>
      </div>
      <div className="flex flex-row items-center gap-4">
      {/* {!loading && user && (
            <p className="block px-4 rounded-full text-gray-700   dark:text-gray-400   dark:border-gray-700 capitalize">
              you have {user.credits} credits
            </p>
        )} */}
                  <button onClick={handleLogout} className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
    Logout
  </button>
      </div>
    </div>

  </div>

  <div id="mobile" className="relative block md:hidden w-full">
    <div className="flex justify-between items-center">
      <div className="md:flex md:gap-12">
      <a href="/my/"><Image src='sav-logo-tp.png' className='w-16' alt='sav-logo' imgClass='w-16'/></a>
      </div>
      <a className='flex flex-col hover:*:text-lg hover:cursor-pointer bg-purple-50 rounded-xl p-2' href='/products/investing-course'>
            <p className='font text-xs md:text-md'>Design a personalised investment strategy</p>
            <p className='text-underline font-bold text-green-600 text-xs md:text-md'>Start risk free ↗</p>
          </a>
      <div>
      <button onClick={toggleMobileDropdown} className="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16"/>
        </svg>
      </button>
      <div className={`absolute top-full left-0 w-full my-2 pb-4 px-4 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden z-10 ${
        isMobileDropdownOpen ? 'block' : 'hidden'
      }`} 
      id="mobileDropdown"
    >
      <ul className="w-full text-md">
        {/* <li><a className="block hover:bg-indigo-100 px-4 py-2" href="/my/resume-feedback">Resume Feedback</a></li>
        <li><a className="block hover:bg-indigo-100 px-4 py-2" href="/my/get-cover-letter">Cover Letter</a></li> */}
        <li><a className="block hover:bg-indigo-100 px-4 py-2" href="/my/career">Tools</a></li>
        <li><a className="block hover:bg-indigo-100 px-4 py-2" href="/my/resources">Resources</a></li>
        <li><a className="block hover:bg-indigo-100 px-4 py-2" href="/my/profile">My Profile</a></li>
        <li><a className="block hover:bg-indigo-100 px-4 py-2" href="https://club.sav.finance">sav Club</a></li>
      </ul>
      <button onClick={handleLogout} className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
    Logout
  </button>
      </div>
    </div>
  </div>
  </div>
</nav>
    );
}

const handleLogout = () => {
  fetch('/my/api/logout', { method: 'GET' }) // Adjust the endpoint as necessary
    .then(() => {
      // Redirect to login page or handle UI changes
      window.location.href = '/'; // Update with your login route
    })
    .catch(error => console.error('Logout failed:', error));
};

export default withGlobalState(Nav);

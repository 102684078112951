import React from 'react';
import "../../App.css";

const GIF = ({ src = "loading.mp4", className}) => {
  const videoPath = `/images/${src}`; 
  const defaultClasses = "absolute inset-0 z-50 flex justify-center items-center";
  return (
    <div className={className ? className : defaultClasses}>
      <video className="h-auto" autoPlay loop muted>
        <source src={videoPath} type="video/mp4" />
      </video>
    </div>
  );
};

export default GIF;

import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


const CalculateRetirement = () => {
  const [currentAge, setCurrentAge] = useState('');
  const [retirementAge, setRetirementAge] = useState('');
  const [lifeExpectancy, setLifeExpectancy] = useState('');
  const [desiredIncome, setDesiredIncome] = useState('');
  const [returnRate, setReturnRate] = useState('');
  const [result, setResult] = useState('');

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Net Worth Over Time (in Thousands)',
        data: [],
        backgroundColor: 'rgba(0, 123, 255, 0.5)',
        borderColor: 'rgba(0, 123, 255, 1)',
        borderWidth: 1,
      }
    ]
  });

  

  const calculateRetirement = () => {
    const parsedCurrentAge = parseInt(currentAge);
    const parsedRetirementAge = parseInt(retirementAge);
    const parsedLifeExpectancy = parseInt(lifeExpectancy);
    const parsedDesiredIncome = parseFloat(desiredIncome);
    const parsedReturnRate = parseFloat(returnRate) / 100;
    const labels = [];
    const data = [];

  
    if (isNaN(parsedCurrentAge) || isNaN(parsedRetirementAge) || isNaN(parsedDesiredIncome) || isNaN(parsedReturnRate)) {
      setResult('Please fill in all fields with valid numbers.');
      return;
    }
  
    const monthlyReturnRate = Math.pow(1 + parsedReturnRate, 1 / 12) - 1;
    const monthsUntilRetirement = (parsedRetirementAge - parsedCurrentAge) * 12;
    const monthsOfRetirement = (parsedLifeExpectancy - parsedRetirementAge) * 12;
  
    let retirementFundNeeded = 0;
    for (let month = 1; month <= monthsOfRetirement; month++) {
      retirementFundNeeded += parsedDesiredIncome / Math.pow(1 + monthlyReturnRate, month);
    }
  
    const monthlyInvestment = retirementFundNeeded / (((1 + monthlyReturnRate) ** monthsUntilRetirement - 1) / monthlyReturnRate);
    setResult(`Monthly Investment Required: $${monthlyInvestment.toFixed(2)}`);
  
    let currentNetWorth = 0;
    for (let month = parsedCurrentAge * 12; month <= parsedLifeExpectancy * 12; month++) {
      if (month < parsedRetirementAge * 12) {
        currentNetWorth = currentNetWorth * (1 + monthlyReturnRate) + monthlyInvestment;
      } else {
        currentNetWorth = currentNetWorth * (1 + monthlyReturnRate) - parsedDesiredIncome;
      }
  
      if (month % 12 === 0) {
        const age = Math.floor(month / 12);
        labels.push(age);
        data.push(currentNetWorth / 1000);
      }
    }
  
    setChartData({
      labels: labels,
      datasets: [
        {
          label: 'Net Worth Over Time (in Thousands)',
          data: data,
          backgroundColor: 'rgba(0, 123, 255, 0.5)',
          borderColor: 'rgba(0, 123, 255, 1)',
          borderWidth: 1,
        }
      ],
    });
  };
  


  return (
    <div className="flex flex-col bg-white gap-8 items-center py-4 mx-4 md:mx-auto max-w-screen-lg xl:gap-8  sm:py-4 md:pt-8 px-4 lg:px-6 rounded-3xl m-8 justify-center">
      <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-gray-900 capitalize">How much do you
        actually need to retire?</h2>
      <p className="mb-6 text-gray-500 dark:text-gray-400">Don't agha agha your retiremnet needs. This calculator will show you
    the amount of money you need to retire so there is no guessing. The goal is to find the lowest amonut of money you would theoretically need to put aside such that by the year you pass away, you will have no money left.</p>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-x-8 *:flex *:flex-col *:md:flex-row *:md:justify-between *:md:items-center">
      <div>
        <label htmlFor="currentAge">Current Age:</label>
        <input className='border-2 p-2 m-0 md:m-2' type="number" id="currentAge" value={currentAge} onChange={e => setCurrentAge(e.target.value)} />
      </div>
      <div>
        <label htmlFor="retirementAge">Desired Retirement Age:</label>
        <input className='border-2 p-2 m-0 md:m-2' type="number" id="retirementAge" value={retirementAge} onChange={e => setRetirementAge(e.target.value)} />
      </div>
      <div>
        <label htmlFor="lifeExpectancy">Life Expectancy:</label>
        <input className='border-2 p-2 m-0 md:m-2' type="number" id="lifeExpectancy" value={lifeExpectancy} onChange={e => setLifeExpectancy(e.target.value)} />
      </div>
      <div>
        <label htmlFor="desiredIncome">Ideal Monthly Retirement Income:</label>
        <input className='border-2 p-2 m-0 md:m-2' type="number" id="desiredIncome" value={desiredIncome} onChange={e => setDesiredIncome(e.target.value)} />
      </div>
      <div>
        <label htmlFor="returnRate">Expected Annual Return Rate (%):</label>
        <input className='border-2 p-2 m-0 md:m-2' type="number" id="returnRate" value={returnRate} onChange={e => setReturnRate(e.target.value)} />
      </div>
    </div>

      <button onClick={calculateRetirement} className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded my-4">Tell Me</button>

      {/* Display the result */}
      {result && (
        <div>
          <p className='font-bold'>{result}</p>
        </div>
      )}
<div className='w-full'>
  <Accordion type="single" collapsible>
    <AccordionItem value="item-1">
      <AccordionTrigger>Show chart</AccordionTrigger>
      <AccordionContent>
      <div className='w-full aspect-video'>
          <Line 
            data={chartData}
            options={{
              scales: {
                x: {
                  // ... x-axis options
                },
                y: {
                  // ... y-axis options
                },
              },
              responsive: true,
              maintainAspectRatio: false,
            }}
          />
      </div>
      </AccordionContent>
    </AccordionItem>
  </Accordion>
</div>

      
  </div>
  );
};


export default CalculateRetirement;
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Button } from "./ui/button"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/alert-dialog"


function InvestmentSimulation({ lastSaveTime }) {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      label: 'Total Ending Balance',
      data: [],
      fill: false,
      backgroundColor: 'rgb(75, 192, 192)',
      borderColor: 'rgba(75, 192, 192, 0.2)',
    }]
  });
  const [foundation, setFoundation] = useState([]); // Add state to keep track of foundation data
  const [tableData, setTableData] = useState([]);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(-1);
  const [emotionSelections, setEmotionSelections] = useState({});
  const [showAllStocks, setShowAllStocks] = useState(false);
  const [showChart, setShowChart] = useState(false);

  const calculateLastSelectedIndex = (results, selections) => {
    let lastIndex = -1;
    Object.keys(results).forEach((yearKey, index) => {
      const year = yearKey.match(/\d+/)[0]; // Extracting year from yearKey
      // Check if there's a selection for the year and it's not the placeholder
      if (selections[`year ${year}`] && selections[`year ${year}`] !== '') {
        lastIndex = Math.max(lastIndex, index);
      }
    });
    return lastIndex;
  };
  

  useEffect(() => {

    fetch('/my/api/get-simulation') 
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(jsonData => {
        console.log(jsonData);
        if (jsonData) {
          setFoundation(jsonData.foundation);
          processDataForTableAndChart(jsonData); // Ensure this processes data correctly
          setEmotionSelections(jsonData.results['feeling'] || {});
  
          // Calculate the last selected index after the emotions have been set
          const lastIndex = calculateLastSelectedIndex(jsonData.results, jsonData.results['feeling'] || {});
          setLastSelectedIndex(lastIndex);
        } else {
          console.error('Simulation data is missing');
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [lastSaveTime]);
  
  const toggleStocksVisibility = () => {
    setShowAllStocks(!showAllStocks);
  };

  const toggleChartVisibility = () => {
    setShowChart(!showChart);
  };
  
  const processDataForTableAndChart = (jsonData) => {
    const { foundation, results } = jsonData;
    let processedTableData = [];
    let chartLabels = [];
    let chartValues = [];

  
    // Assuming foundation is an array of objects, each containing a stock array
    let stockNames = [];
    foundation.forEach(foundationItem => {
      // Iterate over the keys of each foundationItem
      Object.keys(foundationItem).forEach(key => {
        // Check if the key ends with 'symbol'
        if (key.endsWith('symbol')) {
          // Extract and add the stock symbol to the stockNames array
          stockNames.push(foundationItem[key]);
        }
      });
    });
  
    Object.entries(results).forEach(([yearKey, data]) => {
      const year = parseInt(yearKey.match(/\d+/)[0], 10); // Assuming yearKey is already the year you need
      let stocksData = stockNames.map(stockName => {
        // Construct the keys to look for in the data
        const returnKey = `${stockName} return (%)`;
        const totalValueKey = `${stockName} total value`;
  
        // Extract the values using the constructed keys
        const returnPercent = data[returnKey] || 0; // Default to 0 if not found
        const totalValue = data[totalValueKey] || 0; // Default to 0 if not found
  
        return {
          name: stockName,
          returnPercent,
          endingValue: totalValue,
        };
      });
  
      // Assuming totalEndingBalance and averageReturn are directly under results for each year
      processedTableData.push({
        year: year,
        stocks: stocksData,
        totalEndingBalance: data['ending balance'], // Make sure to adjust based on your actual key names
        averageReturn: data['total p&L (%)'], // Make sure to adjust based on your actual key names
        feeling: data['feeling'], // Adjust or populate based on your requirements
      });
  
      chartLabels.push(`Year ${year}`);
      chartValues.push(data['ending balance']); // Adjust based on actual key names
    });
    processedTableData.sort((a, b) => a.year - b.year);

    // Update chart data based on sorted processedTableData
    chartLabels = processedTableData.map(data => `Year ${data.year}`);
    chartValues = processedTableData.map(data => data.totalEndingBalance);
    setTableData(processedTableData);
    setChartData({
      labels: chartLabels,
      datasets: [{
        label: 'Total Ending Balance',
        data: chartValues,
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
      }]
    });
  };

  const handleEmotionChange = (year, emotion, index) => {
    const newEmotionSelections = { ...emotionSelections, [year]: emotion };
    setEmotionSelections(newEmotionSelections);
    setLastSelectedIndex(index);
  
    // Send the updated emotion for the specific year to your backend
    fetch('/my/api/update-simulation-emotion', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        year,
        emotion,
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };
  

  const renderDropdown = (year, index, currentFeeling = '') => (
    <select
      value={emotionSelections[`year ${year}`] || currentFeeling}
      onChange={(e) => handleEmotionChange(`year ${year}`, e.target.value, index)}
      className="emotion-dropdown"
      disabled={currentFeeling && emotionSelections[`year ${year}`]}
    >
      <option value="" disabled={emotionSelections[`year ${year}`] !== ''}>Select...</option>
      <option value="1">🤬</option>
      <option value="2">😰</option>
      <option value="3">😢</option>
      <option value="4">😓</option>
      <option value="5">😅</option>
      <option value="6">🙂</option>
      <option value="7">😊</option>
      <option value="8">😀</option>
      <option value="9">😁</option>
      <option value="10">😍</option>
    </select>
  );

  useEffect(() => {
    const firstYearWithoutFeelingIndex = tableData.findIndex(yearData => yearData.feeling === '');
    setLastSelectedIndex(firstYearWithoutFeelingIndex !== -1 ? firstYearWithoutFeelingIndex - 1 : tableData.length - 1);
  }, [tableData]);
  

  return (
    <div className='mx-4 my-4'>
      <div className='flex flex-row *:mx-2'>
      <Button  variant="outline" onClick={toggleStocksVisibility}>{showAllStocks ? 'Hide All Columns' : 'Show All Columns'}</Button>
      <AlertDialog>
        <AlertDialogTrigger>
        <Button variant="outline"> {showChart ? 'Hide Chart' : 'Show Chart'}</Button>
      </AlertDialogTrigger>
  <AlertDialogContent>
    <AlertDialogHeader>
      <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
      <AlertDialogDescription>
        We highly recommend you go through each year first before you look at the chart so that you have a better idea to your emotions.
      </AlertDialogDescription>
    </AlertDialogHeader>
    <AlertDialogFooter>
      <AlertDialogCancel>Cancel</AlertDialogCancel>
      <AlertDialogAction onClick={toggleChartVisibility}>Continue</AlertDialogAction>
    </AlertDialogFooter>
  </AlertDialogContent>
</AlertDialog>
      
      </div>
      <table className='mx-auto'>
        <thead>
          <tr className='*:text-right *:w-24 *:text-pretty *:px-2'>
            <th className='text-start'>Year</th>
            <th>Total Ending Balance</th>
            <th className='bg-gray-100'>Average Return (%)</th>
            <th>How you feel</th>
            {showAllStocks && foundation.map((stock, index) => {
              const stockSymbolKey = `stock ${index} symbol`;
              return (
                <React.Fragment key={index}>
                  <th className='text-pretty bg-gray-100 px-2'>{stock[stockSymbolKey]}<br/> Return (%)</th> {/* Displaying 'stock X name' */}
                  <th className='text-pretty'>{stock[stockSymbolKey]}<br/> Ending Value</th> {/* Displaying 'stock X symbol' */}
                </React.Fragment>
              );
            })}
          </tr>
        </thead>
        <tbody>
        {tableData.map((yearData, yearIndex) => {
  // Determine if the year should be displayed based on the presence of a feeling or its index
  return (
    <React.Fragment key={yearIndex}>
      {yearIndex <= lastSelectedIndex +1 && (
        <tr key={yearIndex} className='*:px-2'>
          <td>{yearData.year}</td>
          <td className='text-right'>${yearData.totalEndingBalance}</td>
          <td className='text-right bg-gray-100'>{yearData.averageReturn}%</td>
          <td>{renderDropdown(yearData.year, yearIndex, yearData.feeling)}</td>
          {showAllStocks && yearData.stocks.map((stock, stockIndex) => (
            <React.Fragment key={stockIndex}>
              <td className='text-right bg-gray-100 px-2'>{stock.returnPercent}%</td>
              <td className='text-right px-2'>${stock.endingValue}</td>
            </React.Fragment>
          ))}
        </tr>
      )}
    </React.Fragment>
  );
})}

</tbody>



      </table>
      {showChart && (
      <div className="aspect-video">
        <Line data={chartData} options={{ scales: { y: { beginAtZero: true } } }} />
      </div>
      )}
    </div>
  );
};

export default InvestmentSimulation;

import React from 'react';

const Slido = ({ id }) => {
  const src = `https://app.sli.do/event/${id}`;
  
  return (
    <iframe
      frameBorder="0"
      width="100%"
      height="100%"
      src={src}
      style={{ minHeight: '560px' }}
      title="Slido"
    ></iframe>

  );
}

export default Slido;

import React, { useEffect, useState, useRef } from 'react';
import ConvertKitForm from 'convertkit-react'
import Image from '../components/Image'
import ImageGrid from '../components/ImageGrid'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion"
import { Helmet } from 'react-helmet';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../components/ui/carousel"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card"
import StickyBar from '../components/ui/sticky-banner'
import Countdown from '../components/Countdown'
import BasicForm from '../components/BasicForm'

const InvestingCourse = () => {
  return (
    <div>
      <Helmet>
        <title>Sav Finance</title>
        <meta name="description" content='The best place for 🇸🇬 women to learn how to ✨adult✨
Useful AF tips for your Money · Career · Wellbeing'/>
        <meta charSet="utf-8" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.png" />
        <meta property="og:title" content="The best place for 🇸🇬 women to learn how to ✨adult✨
Useful AF tips for your Money · Career · Wellbeing"/>
        <meta property="og:description" content="The best place for 🇸🇬 women to learn how to ✨adult✨
Useful AF tips for your Money · Career · Wellbeing"/>
        <meta property="og:image" href="%PUBLIC_URL%/favicon.png" />
      </Helmet>

      {/* <StickyBar title='Course Closes in' 
      paragraph={<Countdown targetDateString={'20-02-2024-23-59'} />}
      ctaLink='https://cart.sav.finance/start-investing/?coupon=2024'
      ctaText='Sign Up Now'
      bgColor='bg-red-50'
      /> */}


      
    <div className="">
      <section className=" dark:bg-gray-900 space-y-6 py-8 bg-amber-50">
        <div className="flex flex-col md:flex-row gap-8 px-4 mx-auto max-w-screen-lg items-center">
          <div className="flex flex-col pb-8 gap-y-6">
            <h1 className="text- md:text-start font-extrabold tracking-tight leading-none text-gray-900 text-4xl md:text-6xl text-balance font-serif">Get a pulse on what 🇸🇬 women care about</h1>
            <ImageGrid urls={['corp/2402-sav-corp-1.jpg', 'corp/2402-sav-corp-2.jpg', 'corp/2402-sav-corp-3.jpg', 'corp/2402-sav-corp-4.jpg', 'corp/2402-sav-corp-5.jpg',]} className='block md:hidden flex flex-col gap-4'/>
            <h2 id='cta' className='text-start text-2xl text-pretty'>Subscribe to our monthly newsletter where we share insights to what we learn so that you can make better marketing decisions.</h2>
            <BasicForm  CTA='Get Monthly Updates' fetch_url='/my/api/corp-nl' success="🎉 You'll get the next newsletter!" />
            
          </div>
          <div className='hidden md:block rounded-2xl basis-1/3 flex-none'>
            <h2 className='text-2xl font-semibold text-center py-2'>Part of our media kit</h2>
          <ImageGrid urls={['corp/2402-sav-corp-1.jpg', 'corp/2402-sav-corp-2.jpg', 'corp/2402-sav-corp-3.jpg', 'corp/2402-sav-corp-4.jpg', 'corp/2402-sav-corp-5.jpg']}/>
          <p className='pt-4'>Click to expand. <a href='/go/media'><span className='text-center text-blue-600 font-semibold pt-4'>Get the latest media kit here.</span></a></p>
          </div>
        </div>
      </section>


      <section id="instructors" className='mx-auto py-8 px-4 bg-amber-50'>
      <div className='p-6 md:p-16 bg-white mx-auto max-w-screen-sm *:text-md'>
  <p>Hi</p>
  <p className='pt-4'>I’m Chungsoon, CEO here at sav finance.</p>
  <p className='pt-4'>I won't go through all the stats about the sav audience (<a href='/go/media' className='text-blue-600 font-semibold'>get it here</a>) But I’m proud of the trust we have with them.</p>
  <p>As 5%+ have reached out to us for advice.</p>

  <p className='pt-4'>Unlike most brands, our goal isn’t to just be a media company.</p>
  <p>Our goal is for them to tell us that they’ve saved their first $1000. </p>
  <p>That they’ve gotten that job.</p> 
  <p className='font-bold'>That we made a material impact on their lives.</p>

  <p className='pt-4'>To do that, we’ve expanded out of just creating content and started to create virtual workshops.</p>
  <p>We did 7 of them in 2023. All of them had 100+ signups.</p>
  <p>With our larger events getting <span className='font-bold'>500-1100 signups.</span></p>
  <p>We aim to wrap up in 2hrs. But never get through all the questions.</p>

  <p className='pt-4'>This year we're taking it further.</p>
  <p>We started to create and sell digital products.</p>
  <p>It's a way for them to have a tool that can help them daily.</p>

  <p className='pt-4'>We still have lots of ideas on how we can help.</p>
  <p>But we don’t have the resources to make it possible.</p>
  <p className='font-bold'>And that could be where you come in.</p>

  <p className='pt-4'>If you too are looking for a blue ocean.</p>
  <p>Or want your brand to be top of mind.</p>
  <a href="mailto:cs@sav.finance" className='text-blue-600 font-semibold'>Let’s hop on a call.</a>

  <p className='pt-4'>In the meantime, might I suggest <a href='#cta' className='text-blue-600 font-semibold'>signing up for our newsletter.</a></p>
  <p>I'll write to you once a month. I’ll share mistakes we’ve made. The lessons we’ve learned. And plans we have.</p>
  <p className='pt-4'>With the hopes that you will find our work meaningful and would want to support it in the future.</p>
  <div className='pt-6 flex flex-row gap-4'>
    <Image src='chungsoon1x1v2.jpg' className='w-16' imgClass='rounded-full'/>
    <div className='flex flex-col content-end'>
      <span className='font-bold'>Chungsoon Haw</span>
      <span className='font-italic'>Co-founder & CEO</span>
    </div>
  </div>
</div>


      </section>
      

    </div>
    </div>
  );
}

export default InvestingCourse;
import React, { useRef } from 'react';

const Popup = ({ show, content, onClose }) => {
    const popupContentRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupContentRef.current && !popupContentRef.current.contains(event.target)) {
            onClose(); // Close the popup if clicked outside the content
        }
    };

    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" onClick={handleClickOutside}>
            <div className="relative top-20 max-w-screen-lg mx-auto p-5 border shadow-lg rounded-md bg-white" ref={popupContentRef}>
                <div className="flex justify-between items-start">
                    <div className="flex flex-col items-center flex-grow">
                        {content}
                    </div>
                    <button onClick={onClose} className="ml-4 bg-gray-100 hover:bg-gray-200 rounded-full px-2 py-2">
                        X
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Popup;

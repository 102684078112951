import React, { useEffect, useState, useRef } from 'react';
import Cal, { getCalApi } from "@calcom/embed-react";
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import GIF from './components/GIF';
import Respondio from './components/Respondio';
import { Widget } from '@typeform/embed-react'
import TypeformEmbed from './components/TypeformEmbed';
import { useToast } from "./components/ui/use-toast"
import { Toaster } from "./components/ui/toaster"
import PropTypes from 'prop-types';
import YoutubeVideo from './YoutubeVideo';
import Slido from './Slido';
import Retirement from './components/Retirement';
import InvestingCourse from './lp/InvestingCourse';


const TimedContent = () => {
  const { toast } = useToast();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Set the target date and time
    const targetDate = new Date('2024-01-22T21:30:00+08:00'); // 18 Jan 2024, 9:30 PM SGT

    // Calculate the time difference from now to the target time in milliseconds
    const timeDifference = targetDate.getTime() - new Date().getTime();

    // Set a timer to show the content at the target time
    const timer = setTimeout(() => {
      setIsVisible(true);
      // toast({
      //   title: "Book Your Meeting",
      //   description: `Start your insurance conversation with Candy & Aly before their slots run out`,
      //   action: <a href="#book" className="bg-purple-300 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">Book Now</a>
      // });
    }, timeDifference);

    // Cleanup timeout on unmount
    return () => clearTimeout(timer);
  }, []); // Empty dependency array to run effect only once on mount

  return (
    <div>
      {isVisible && (
        <div id="book" className='w-max-2xl p-8'>
          <InvestingCourse/>
        </div>
      )}
    </div>
  );
};

TimedContent.propTypes = {
  min: PropTypes.number.isRequired,
};
function CalEmbed() {
	useEffect(()=>{
	  (async function () {
      const cal = await getCalApi();
      console.log("Cal object:", cal);
      console.log("Cal.ns object:", cal.ns);
      if (cal && cal.ns) {
        console.log("Accessing cal.ns.insurance");
		    cal.ns.insurance("ui", {"styles":{"branding":{"brandColor":"#000000"}},"hideEventTypeDetails":false,"layout":"month_view"});
      } else {
        console.log("cal or cal.ns is undefined");
      }
	  })();
	}, [])

	return <Cal namespace="insurance"
	  calLink="team/sav-money-expert/insurance"
	  style={{width:"100%",height:"100%",overflow:"scroll"}}
	  config={{layout: 'column_view'}}
    
	  
	/>;
  };

const MyComponent = () => {
  return <Widget id="<XtmDOSw7>" style={{ width: '50%' }} className="my-form" />
}

const Workshop = () => {
  const [data, setData] = useState(null);
  const [member, setMember] = useState(null);
  const [loadingAdditional, setLoadingAdditional] = useState(true);
  const [errorAdditional, setErrorAdditional] = useState(null);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    setIsLoading(true); // Start loading before the fetch begins
    fetch(`/my/api/workshop?id=${id}`)
      .then(response => {
        if (response.status === 401) { // Check if the status is 401
          return response.json();
        }
        return response.json();
      })
      .then(data => {
        if (data && data.error) {
          // Set the error message received from the backend
          setErrorAdditional(data.error);
          setLoadingAdditional(false);
        } else {
          // Set the data received
          setData(data);
          setLoadingAdditional(false);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setErrorAdditional(error.toString());
        setLoadingAdditional(false);
      })
      .finally(() => {
        setIsLoading(false); // End loading after fetch is complete or failed
      });
  }, [id]);

  useEffect(() => {
    fetch(`/my/api/data`)
      .then(response => response.json())
      .then(data => {
        setMember(data);
        setLoadingAdditional(false);
        console.log(member)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setErrorAdditional(error);
        setLoadingAdditional(false);
      });
  }, []);
  
  // In your component's render method or return statement
  

  if (isLoading) {
    return <GIF />;
  }
  

  // Combine loading states and error states

  if (loadingAdditional) {
    return <div>Loading Workshop...</div>;
  }

  if (errorAdditional) {
    return <div>Error: You don't have access to this event</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }


	return (
		<div className="Workshop">
      <Helmet>
        <title>{data.title}</title>
        <meta name="description" content={`${data.title} is a members only event`} />
      </Helmet>
      {errorAdditional && <p>Error: {errorAdditional}</p>}
			<header className="My-Profile">
				{/* Calling a data from setdata for showing */}
        <section className=" dark:bg-gray-900 items-center bg-slate-100">
        {/* <TypeformEmbed id={"01HM8TZM6HK7276FMYHJ255ARJ"}/> */}
  <div className="flex flex-col justify-center py-4 px-4 mx-auto max-w-screen-xl sm:text-center p-4 md:p-8">
    <h1 className="font-serif text-4xl font-bold leading-none lg:text-5xl mb-6 text-center">{data.title}</h1>
    <h2 className="font-sans text-2xl text-center mb-8 text-pretty">{data.sub}</h2>
    <div className="flex flex-col md:flex-row gap-8 justify-self-center">
      <div className="bg-white p-4 rounded-xl">
        <YoutubeVideo videoId={data.youtubeID} />
        <p className="font-light text-gray-700 text-start">We recommend you watch this the video on this page so you can also participate in the polls & Q&A on Slido</p>
      </div>
      <div className="bg-white p-4 rounded-xl">
      <Slido id={data.slidoID} />
      </div>
    </div>
  </div>
  <TimedContent/>
  <Retirement/>
        <Respondio/>
        </section>
			</header>
      
		</div>
	);
}

export default Workshop;

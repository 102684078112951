import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDmRtPLx96hBJVdO6CK7nAXh9mxoMYCJGI",
  authDomain: "sav-career-support.firebaseapp.com",
  databaseURL: "https://sav-career-support-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "sav-career-support",
  storageBucket: "sav-career-support.appspot.com",
  messagingSenderId: "722336400872",
  appId: "1:722336400872:web:c33f51ce31aab523f0905d",
  measurementId: "G-NK1EQH8MPY"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
import React, { useEffect, useState, useRef } from 'react';
import ConvertKitForm from 'convertkit-react'
import Image from '../components/Image'
import MBG from '../components/MBG'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion"
import { Helmet } from 'react-helmet';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../components/ui/carousel"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card"
import StickyBar from '../components/ui/sticky-banner'
import Countdown from '../components/Countdown'

const InvestingCourse = () => {
  return (
    <div>
      <Helmet>
        <title>Sav Finance</title>
        <meta name="description" content='The best place for 🇸🇬 women to learn how to ✨adult✨
Useful AF tips for your Money · Career · Wellbeing'/>
        <meta charSet="utf-8" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.png" />
        <meta property="og:title" content="The best place for 🇸🇬 women to learn how to ✨adult✨
Useful AF tips for your Money · Career · Wellbeing"/>
        <meta property="og:description" content="The best place for 🇸🇬 women to learn how to ✨adult✨
Useful AF tips for your Money · Career · Wellbeing"/>
        <meta property="og:image" href="%PUBLIC_URL%/favicon.png" />
      </Helmet>

      {/* <StickyBar title='Course Closes in' 
      paragraph={<Countdown targetDateString={'20-02-2024-23-59'} />}
      ctaLink='https://cart.sav.finance/start-investing/?coupon=2024'
      ctaText='Sign Up Now'
      bgColor='bg-red-50'
      /> */}


      
    <div className="">
      <section className=" dark:bg-gray-900 space-y-6 py-8 bg-amber-50">
        <div className="flex flex-col md:flex-row gap-8 px-4 mx-auto max-w-screen-lg items-center">
          <div className="flex flex-col pb-8 gap-y-6">
            <h1 className="text- md:text-start font-extrabold tracking-tight leading-none text-gray-900 text-4xl md:text-6xl text-balance font-serif">👋🏼 Hi, we’re sav. <br/>Let’s be money BFFs?</h1>
            <Image src='sav-main.jpeg' className='block md:hidden'/>
            <p className=" text-gray-900 text-lg">You can count on us for all money stuff - from insurance to your career. Explore to find out more about us or tell us a bit more about yourself!</p>
            
          </div>
          <div className='hidden md:block rounded-2xl basis-1/3 flex-none'>
          <Image src='sav-main.jpeg'/>
          </div>
        </div>
      </section>

      <section id="instructors" className='mx-auto py-8 px-4 bg-amber-50'>
        <div className="flex flex-col gap-y-2 max-w-screen-lg mx-auto">
        <h1 className="font-extrabold tracking-tight leading-none text-gray-900 text-center text-4xl md:text-6xl text-balance font-serif py-8">What $$$ matters do you need help with?</h1>

          <div className="flex flex-col md:flex-row gap-4 md:gap-6 mx-4">
            <div className='flex flex-col basis-1/3 bg-white py-8 px-4 rounded-2xl'>
              <Image src='sav-home-ins.png'/>
              <h2 className="font-extrabold tracking-tight leading-none text-gray-900 text-center text-xl md:text-3xl text-balance font-serif py-8">Insurance</h2>
              <div className='text-start text-lg'>
              Our sister brand, Whatins can help get you insurance advice from a wide range of experts for free.
              </div>
              <div className='flex flex-col space-2 *:flex *:flex-row *:gap-x-2 *:items-center *:py-2'>
              <div>
                <Image src='smile-bullet.svg' className='w-6 flex-none'/>
                <p>Questionnaire takes 5mins</p>
              </div>
              <div>
                <Image src='smile-bullet.svg' className='w-6 flex-none'/>
                <p>See what plans others like you bought</p>
              </div>
              <div>
                <Image src='smile-bullet.svg' className='w-6 flex-none'/>
                <p>If you'd like more options, you can also get 5 proposals from different advisors</p>
              </div>
              <div>
                <Image src='smile-bullet.svg' className='w-6 flex-none'/>
                <p>Prefer to work with a sav trusted advisor? Message us on IG and we'll connect you!</p>
              </div>
              <div className='grow'>
              </div>
              <a href='https://whatins.sg' className="bg-purple-500 hover:bg-purple-700 text-gray-900 font-bold py-2 px-4 rounded-2xl"><p className='text-white text-center w-full'>Go to Whatins</p></a>
            </div>

            </div>
            <div className='flex flex-col basis-1/3 bg-white py-8 px-4 rounded-2xl'>
              <Image src='sav-home-career.png' className='flex-none'/>
              <h2 className="font-extrabold tracking-tight leading-none text-gray-900 text-center text-xl md:text-3xl text-balance font-serif py-8">Career</h2>
              <div className='text-start text-lg'>
              Career Tools to level up your job search
              </div>
              <div className='flex flex-col space-2 *:flex *:flex-row *:gap-x-2 *:items-center *:py-2'>
              <div>
                <Image src='smile-bullet.svg' className='w-6 flex-none'/>
                <p>Get feedback on your resume</p>
              </div>
              <div>
                <Image src='smile-bullet.svg' className='w-6 flex-none'/>
                <p>Get a cover letter written for your job application</p>
              </div>
              <div>
                <Image src='smile-bullet.svg' className='w-6 flex-none'/>
                <p>Free for all sav members</p>
              </div>
            </div>
            <div className='grow'>
              </div>
              <a href='/register' className="bg-purple-500 hover:bg-purple-700 text-gray-900 font-bold py-2 px-4 rounded-2xl"><p className='text-white text-center w-full'>Register for account</p></a>

            </div>
            <div className='flex flex-col basis-1/3 bg-white py-8 px-4 rounded-2xl align-top'>
              <Image src='sav-home-learn.png' className='flex-none'/>
              <h2 className="font-extrabold tracking-tight leading-none text-gray-900 text-center text-xl md:text-3xl text-balance font-serif py-8">Learn</h2>
              <div className='text-start text-lg'>
              And many more useful AF adulting tips for you
              </div>
              <div className='flex flex-col space-2 *:flex *:flex-row *:gap-x-2 *:items-center *:py-2'>
              <div>
                <Image src='smile-bullet.svg' className='w-6 flex-none'/>
                <p>Regular adulting workshops, from personal finance to career</p>
              </div>
              <div>
                <Image src='smile-bullet.svg' className='w-6 flex-none'/>
                <p>New posts on money, career and wellbeing on our Instagram</p>
              </div>
              <div>
                <Image src='smile-bullet.svg' className='w-6 flex-none'/>
                <p>Digital goods to help make adulting easier</p>
              </div>
              </div>
              <div className='grow'>
              </div>
              <a href='https://www.instagram.com/sav.finance/' className="bg-purple-500 hover:bg-purple-700 text-gray-900 font-bold py-2 px-4 rounded-2xl"><p className='text-white text-center w-full'>Go to sav IG</p></a>
            </div>
          </div>
         
        </div>
      </section>
      

    </div>
    </div>
  );
}

export default InvestingCourse;
import React, { useEffect, useState, useContext } from 'react';
import { GlobalContext } from '../GlobalState';

export const withGlobalState = WrappedComponent => {
  const ComponentWithGlobalState = (props) => {
    const { user, loading, error } = useContext(GlobalContext);

    return <WrappedComponent user={user} loading={loading} error={error} {...props} />;
  };

  return ComponentWithGlobalState;
};
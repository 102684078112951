import React, { useEffect, useState } from 'react';
import { withGlobalState } from './components/HOC';

import YoutubeVideo from './YoutubeVideo';
import Slido from './Slido';


const HomePage = ({ user, loading: loadingGlobal, error: errorGlobal }) => {
  console.log("HomePage is being rendered");
  const [data, setData] = useState(null);
  const [loadingAdditional, setLoadingAdditional] = useState(true);
  const [errorAdditional, setErrorAdditional] = useState(null);

  useEffect(() => {
    fetch('/my/api/workshop')
      .then(response => response.json())
      .then(data => {
        setData(data);
        setLoadingAdditional(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setErrorAdditional(error);
        setLoadingAdditional(false);
      });
  }, []);

  // Combine loading states and error states
  const isLoading = loadingGlobal || loadingAdditional;
  const error = errorGlobal || errorAdditional;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!user || !data) {
    return <div>No data available</div>;
  }


	return (
		<div className="HomePage">
			<header className="My-Profile">
				{/* Calling a data from setdata for showing */}
        <section className=" dark:bg-gray-900 items-center bg-slate-100">
  <div className="py-4 px-4 mx-auto max-w-screen-2xl sm:text-center p-4 md:p-8">
    <h1 className="font-serif text-4xl font-bold leading-none lg:text-5xl mb-8 text-center">{data.videoID}</h1>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="bg-white p-4 rounded-xl">
        <YoutubeVideo videoId={data.videoID} />
      </div>
      <div className="bg-white p-4 rounded-xl">
      <Slido id={data.slidoID} />
      </div>
    </div>
  </div></section>
			</header>
		</div>
	);
}

export default withGlobalState(HomePage);
